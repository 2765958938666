import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import Loading from '../../components/Loading';
import Switch from '@mui/material/Switch';
import FloatSelect from '../../components/FloatSelect';
import FloatTextField from '../../components/FloatTextField';

import {addZeroBefore, isPhoneNumberValid, setCookie, removeCookie, Legalentity_super_admin, superadmin_all_Showrooms} from '../../utils/utils';

import {saveAdmin, getEmployeeByPhone} from '../../Actions/admin.actions';
import {PCC_crm_version, baseURL} from '../../Actions/Constants';

import Logo from '../../components/Logo';
import logo_2 from '../../assets/security-user.png';
import './login.css';

const Login = ({onUpdate}) => {
	const navigate = useNavigate();
  const {t} = useTranslation();

	const [phoneNumber, setPhoneNumber] = useState("");
	const [isInvalidPhone, setInvalidPhone] = useState(false);
  const [isFormSubmit, setFormSubmit] = useState(false);
  const [isInvalidOTP, setInvalidOTP] = useState(false);
  const [isInvalidUser, setIsInvalidUser] =  useState(false);
  const [le, setLE] = useState("");
  const [pos, setPOS] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [isShowOTPField, setShowOTPField] = useState(false);
	
  const saveAdminAction = saveAdmin;
  const getEmployeeByPhoneAction = getEmployeeByPhone; 
  const dispatch = useDispatch();

  const {
    details,
    loading,
    error
  } = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));

  useEffect(() => {

    if(isFormSubmit) {
      if(details && details.emp_phone_no === phoneNumber) {
        if(!isShowOTPField) {
          setShowOTPField(true);
          getOTP();
          setIsInvalidUser(false);
          setFormSubmit(false);
        }
      } else if(details === undefined) {
        setIsInvalidUser(true);
      }
    }
  });

  const onHandleAlert = () => {
    //setError(false);
  }
  
  const handleKeydown = (e, index) => {
  	const keyCode = e.which;
  	
  	switch(keyCode) {
  		case 37:
  		case 38:
  			if(index <= 3 && index> 0) {
		    	const ele = document.getElementById(`digit-${index}`);
		    	ele.focus();
    		}
    	break;
    	case 39:
  		case 40:
  			if(index <= 2) {
		    	const ele = document.getElementById(`digit-${index+2}`);
		    	ele.focus();
    		}
    	break;
    	default: break;
  	}
  }

  const handlePhoneNumber = (e) => {
  	setPhoneNumber(e.target.value);
  }

  const handleChange = (e, index) => {
    let element = e.target;
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d,idx) => (idx === index) ? element.value: d)]);
    element = e.target;
    if(element.value != "" && index < 4) {
      const ele = document.getElementById(`digit-${index+2}`);
      ele.focus();
    }
  }

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setFormSubmit(true);
    setIsInvalidUser(false);

    if(!isShowOTPField) {
      const isNumberValid = isPhoneNumberValid(phoneNumber);
      if(isNumberValid) {
        setInvalidPhone(false);
        dispatch(getEmployeeByPhoneAction(phoneNumber));
        //startTimer();
      } else {
        setInvalidPhone(true);
        const phone_number = document.getElementById("phone_number");
        phone_number.focus();
      }
    } else {
      //Check OTP is valid or not. On success
      const emptyOTPArray = otp.filter((d, idx) => (d ===''));
      if(emptyOTPArray.length !== 0) {
        setInvalidOTP(true);
      } else {
        verifyOTP(otp);
      }
    }
    return false;
  }

  const getOTP = () => {

      const URL = `${baseURL}/getOTP?phone_number=${phoneNumber}`;
      fetch(URL)
      .then(res => res.json())
      .then(res => {
       
      })
      .catch(error => {
        console.log('error', error);
      })
  };

  const verifyOTP = (otp) => {
    
    const receivedOTP = otp.join("");
    const URL = `${baseURL}/verifyOTP?phone_number=${phoneNumber}&OTP=${receivedOTP}`;
    setLoading(true);
    fetch(URL, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
    })
    .then(res=> res.json())
    .then(res => {
      const {type} = res;
      setLoading(false);
      if(type === 'success') {
        dispatch(saveAdminAction(details));
        localStorage.setItem("PCC_crm_version", PCC_crm_version);
        navigate('/device-configure');
      } else {
        setInvalidOTP(true);
      }
    })
    .catch(error => {
      console.log('error', error);
      setLoading(false);
    })
  }

  const resetToInitial = () => {
    setOtp(new Array(4).fill(''));
    setShowOTPField(false);
  }

  const titleVal = "Login - pcccrm";
  const hrefVal = window.location.href;

  const app_version = localStorage.getItem("PCC_crm_version");
  const isSameVersion = (app_version && app_version === PCC_crm_version);

  //check localhost - if exist
  if (!isFormSubmit && !isShowOTPField && details && details.emp_phone_no && details.emp_phone_no !== "" && isSameVersion) return (<Navigate to="/" />);

  return (
    <>
      <Helmet>
        <title>{titleVal}</title>
        <meta name="description" content="Login meta"></meta>
        <link rel="canonical" href={hrefVal} />
      </Helmet>

      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-0">
              <Logo />
            </div>
           
            <div className="login_container_view col-lg-8 col-12 col-md-12">
              <div className="d-flex justify-content-center m-auto h-100 flex-column">
              <div className="d-flex justify-content-center pb-5">
                <img src={logo_2} style={{width : "25%"}} />
              </div>
      				  <form className="login_content_viewer" onSubmit = {e => handleLoginSubmit(e)} autoComplete="off" data-group-name="digits" data-autosubmit="false" noValidate>
        					<div>
                    {!isShowOTPField && <div className="">
                      <div className="py-3 ">
                        <h1 className="fw-bold Roboto font24">{t('login_admin_mobile_number')} *</h1>
                        <div className="Roboto font18">{t('login_sub_text')}.</div>
                      </div>
                      <FloatTextField isRequired={true} classVal="font36" value={phoneNumber} name="phone_number" id="phone_number" label={t("login_admin_mobile_placeholder")} onInputChange = {(e) => handlePhoneNumber(e)} />
                      {isInvalidPhone && <div className="text-danger fw-bold error_text pt-2">! {t("login_invalid_mobile_number")}</div>}
                    </div>}

                    {isShowOTPField && <div className="flex otp-container">
                      <div className="fw-bold pt-5 font24">{t('login_OTP_heading')}</div>
                      <div className="fw-bold font20 py-2">{t('login_Sub_OTP_Text')} {phoneNumber}</div>
                      {otp.map((val, index) => {
                          return (
                            <input
                              className="otp-field font22"
                              id={`digit-${index+1}`}
                              type="text"
                              name={`digit-${index+1}`}
                              maxLength="1"
                              key={index}
                              value={val}
                              focus={index === 0}
                              onKeyDown={e => handleKeydown(e, index)} 
                              onChange={e => handleChange(e, index)}
                            />
                          );
                        }
                      )}
                    </div>}
                    {isInvalidUser && <div className="fw-bold text-danger py-3 error_text">! {t("invalid_admin")}</div>}
                    {isInvalidOTP && <div className="fw-bold text-danger py-3 error_text">! {t("invalid_otp")}</div>}
          	      </div>

                  <div className="d-flex justify-content-end py-5">
                    <button type="submit" className="nextBtn mx-2">{isShowOTPField ? t('verify_confirm') : t('confirm')}</button>
                  </div>
          	    </form>
              </div>
      	    </div>
  	      </div>
        </div>
      </div>
      
      {isFormSubmit && error !== null && <CustomizedSnackbars isShow = {error !== null} text={t("server_error")} showAlert={(isHide) => onHandleAlert(isHide)} />}
      {(isLoading || loading) && <Loading />}
    </>
	)
};

export default Login;
