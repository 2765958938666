import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes , Route, useLocation, Navigate} from 'react-router-dom';
import i18n from 'i18next';
import Login from './pages/Login/Login';
import AdminSetup from './pages/Login/AdminSetup';
import CustomerLogin from './pages/Login/CustomerLogin';
import Footer from './components//Footer';
import Header from './components/Header';
import Landing from './pages/Landing';
import Logout from './pages/Logout';
import SendLink from './pages/Login/SendLink';
import UpdateVoucher from './pages/UpdateVoucher/UpdateVoucher';
import Protected from "./Protected";
import NotFound from "./pages/NotFound";

import './App.css';

const App = () => {
  const [isLogin, setLogin] = useState(false);
  const location = useLocation();

  const onHandle = (val) => {
    setLogin(val);
  }

  return (
    <div className={`parent_container`}>
         <Routes >
            <Route exact path='/feedback' element={<Protected><Landing /></Protected>} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/customer-login' element={<CustomerLogin />} />
            <Route exact path='/device-configure' element={<Protected><AdminSetup /></Protected>} />
            <Route exact path='/update-voucher' element={<Protected><UpdateVoucher /></Protected>} />
            <Route exact path='/' element={<Protected><SendLink /></Protected>} />
            <Route exact path='/logout' element={<Protected><Logout /></Protected>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
    </div>
  );
}

export default App;
