import React, {useState, useEffect, useRef} from 'react';
import people from '../assets/ic_avatar.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import help from '../assets/help.png';
import profile from '../assets/profile.png';
import setting from '../assets/setting.png';
import logout from '../assets/logout.png';

import './userProfile.css';

const UserProfile = ({name = "Guest", email=""}) => {
	const toggleMenu = () => {
		let subMenu = document.getElementById("menu");
		subMenu.classList.toggle("open-menu");
	}

	let useClickOutside = (handler) => {
    let domNode = useRef();
    useEffect(() => {
      let maybehandler = (event) => {
        if (!domNode.current.contains(event.target) && event.target.id !== "img_trigger") {
          handler();
        }
      };
      document.addEventListener("mousedown", maybehandler);
      return () => {
        document.removeEventListener("mousedown", maybehandler);
      };
    });
    return domNode;
  };

  let userMenu = useClickOutside(() => {
  	let subMenu = document.getElementById("menu");
    subMenu.classList.remove("open-menu");
  });

	return (
		<div className="user_profile Roboto">
        	<img id="img_trigger" src={people} className="user-pic px-1" onClick={toggleMenu} />
        	<div className="sub-menu-wrap" id="menu" ref={userMenu}>
				<div className="sub-menu">
					<div className="white">
						<div className="user-info d-flex align-items-center">
							<img src={people} alt="" />
							<div>
								<div>{name}</div>
								<div className="user_name">{email}</div>
								{/*<Link to="/dashboard" target="_blank" className="navigate_dashboard my-3 px-5 py-2 border border-light border border-2 rounded-2">Go to Dashboard</Link>*/}
							</div>
	 					</div>
	 					<hr />
	 					<a href="#" className="sub-menu-link">
	 						<img src={profile} alt="" />
	 						<p>Edit Profile</p>
	 					</a>
	 					<Link to="/update-voucher" className="sub-menu-link">
	 						<img src={help} alt="" />
	 						<p>Update Voucher</p>
	 					</Link>
	 					<a href="#" className="sub-menu-link">
	 						<img src={setting} alt="" />
	 						<p>Privacy</p>
	 					</a>
	 					
	 					<Link to="/logout" className="sub-menu-link">
 							<img src={logout} alt="" />
 							<p>Logout</p>
 						</Link>

 						<div className="navigate_dashboard d-flex justify-content-center">
		 					<a target="_blank" href="https://dev-pcccrm-admin.web.app/" className="p-2">
		 						<span>Go to Dashboard</span>
		 					</a>
	 					</div>
	 				</div>
 				</div>
 			</div>
        </div>
	)
}

export default UserProfile;