import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {useDispatch} from 'react-redux';
import FloatSelect from '../../components/FloatSelect';
import FloatTextField from '../../components/FloatTextField';
import {Salutation, Days, Months, generateYears, getDate, getMonth, getYear, iSDateValid, initialUserFormErrorData} from '../../utils/utils';
import Children from './Children';
import Loading from '../../components/Loading';
import DatePickerField from '../../components/DatePickerView';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';


const UserSpouse = (props) => {
	const {t} = useTranslation();
	const {
		userData={},
		handleChange,
		onUserInfoViewChange,
		handleSpouseDetails,
		handleSpouseBirthDayUpdate,
		handleAnniversaryUpdate,
		addChild,
		handleChildChange, 
		handleChildBirthdayChange
	} = props;
	
	const [isFormValid, setFormValid] = useState(true);
	const [errors, setErrors] = useState(initialUserFormErrorData);
	const [isLoading, setLoading] = useState(false);
	const [isError, setError] = useState(false);
	const dispatch = useDispatch();

	const onHandleAlert = () => {
    	setError(false);
  	}

	const AddCustomer = async () =>  {
	    setLoading(true);
	    const ADD_URL = "https://api.smmc.dev:8908/smmc-pcchandra/addcustomer";
	    const UPDATE_URL = "https://api.smmc.dev:8908/smmc-pcchandra/modifycustomer";
	    
	    const URL = isNewUser ?   ADD_URL : UPDATE_URL;
	    if(isNewUser) {
	    	const d = new Date();
    		userData.onboarding_date = d.getTime();
	    }

	    await fetch(URL, {
	      method: 'POST', // *GET, POST, PUT, DELETE, etc.
	      body: JSON.stringify(userData) // body data type must match "Content-Type" header
	    })
	    .then(res => res.json())
	    .then((res) => {
	      setLoading(false);
	      onUserInfoViewChange(3);
	    })
	    .catch((error) => {
	      console.log('error', error);
	      setLoading(false);
	      setError(true);
	    });
    }

	const handleUserSpouseSubmit = (e) => {
		e.preventDefault();
	
		let isFormValid = validate();
		if(isFormValid) {
			AddCustomer();
		}
	}

	const validate = () => {
    	let isFormValid = true;
    	let isInvalidSpouseGenderError = false;
	    let isInvalidSpouseBirthdayError = false;
	    let isInvalidAnniversaryError = false;

	    setErrors({...errors,
	    	spouseBirthdayError: isInvalidSpouseBirthdayError,
      		anniversaryError : isInvalidAnniversaryError
    	});

    	setFormValid(isFormValid);
    	return isFormValid;
	}

	const {
	    spouse_birthday = "",
	    anniversary = "",
	    gender = "",
	    name = "",
	    onboarding_date = 0
  	} = userData;

  	const { 
  		spouse_name = "",
	    spouse_gender = "",
		children = [
			{
	          child_name: "",
	          child_dob: "",
	          child_gender: ""
	        }
		]
  	} = userData.details;

	const {
  		spouseBirthdayError = false,
    	anniversaryError = false
    } = errors;

    const isNext = () => {
  		
  		const {spouse_name = "", spouse_gender = "", spouse_birthday = "", anniversary = "" } = userData.details;
  		if(anniversary !== "" || spouse_birthday !== "" || spouse_gender !== "" || spouse_name !== "") {
  			return true
  		}
  		return false;
  		
  	}

  	const isNewUser = (onboarding_date === 0) ? true : false;
  	const title_text = isNewUser ? `${t("hello")} ${gender} ${name}!` : `${t("hello")} ${gender} ${name}!, ${t("welcome_back")}!`;
    const sub_title_text = isNewUser ? t("spouse_heading_1") : t("family_details");

	return (
		<div className="container-fluid">
			<div className="px-lg-3 px-1">
				<h1 className="heading">{title_text}</h1>
				<h2 className="heading pt-2">{sub_title_text}</h2>
			</div>
			<form onSubmit={(e) => handleUserSpouseSubmit(e)} className="px-lg-3 pt-4" noValidate>
				
				<div className="row">
					<div className="col-6 my-3">
	                    <FloatSelect name="spouse_gender" label={t("salutation")} list={Salutation} value={spouse_gender} onInputChange = {(e) => handleSpouseDetails(e)} />
	                </div>
	                <div className="col-6 my-3">
	                    <FloatTextField name="spouse_name" label={t("spouse_name")} value={spouse_name} onInputChange = {(e) => handleSpouseDetails(e)} />
	                </div>
				</div>

				<div className="row">
					<div className="col-lg-6 col-md-6 my-3">
	                    <DatePickerField label="Birthday" value={spouse_birthday} name="spouse_birthday" onInputChange = {(val, name) => handleSpouseBirthDayUpdate(val)} />
					</div>
	                <div className="col-lg-6 col-md-6 my-3">
	                    <DatePickerField label="Anniversary" value={anniversary} name="anniversary" onInputChange = {(val, name) => handleAnniversaryUpdate(val)} />
					</div>
				</div>
				
				{spouseBirthdayError && <p className="text-danger fw-bold">! {t("spouse_birthday_error")}</p>}


				<div className="d-flex justify-content-between pt-2">
                	<h4 className="Roboto px-2">{t("landing_children_details")}</h4>
					<button
					  disabled={children.length === 3 ? "true" : ""}
					  onClick={(e) => addChild(e)}
					  className={`addMore ax-button pe-3 ${children.length === 3 ? 'disabled': ''}`}
					  type="button"
					>
					  <i className="fa-solid fa-plus"></i> {t("landing_add_more")} (Max 3)
					</button>
                </div>

				{children.map((item, index) => (
					<Children item = {item} index={index} handleChildBirthdayChange={(val,index)  => handleChildBirthdayChange(val, index)} handleChildChange={handleChildChange} />
				))}

				<div className="d-flex justify-content-end my-4">
      				<button type="button" className="btn btn-outline-danger px-5" onClick={() => onUserInfoViewChange(1)}>
        				<span>{t("back")}</span>
      				</button>

      				<button type="submit" className="btn btn-danger px-5 mx-2">
        				<span className="">{isNext() ? t("next") : t("skip")}</span>
      				</button>
    			</div>
    		</form>
    		{isError && <CustomizedSnackbars isShow={isError} text={t("server_error")} showAlert={(isHide) => onHandleAlert(isHide)} />}
      		{isLoading && <Loading/>}
		</div>
	)
}

export default UserSpouse;