import React, { useEffect } from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import Stepper from 'react-stepper-horizontal';
import MuiStepper from './MuiStepper';
import './stepper.css';

export default function StepperControl(props) {
  const {currentStep = 1, text="About You", subActiveStep = 1} = props;

  const [activeStep, setActiveStep] = React.useState(currentStep - 1);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //setActiveStep(currentStep - 1);
  };

  const handleBack = () => {
    //setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setActiveStep(currentStep - 1 );
  };

  useEffect(() => {
   setActiveStep(currentStep - 1);
  },[currentStep]);

  return (
    <div>
      <div className="stepperText_container pb-2">
        {activeStep === 0 && <span className="stepperText">{`0${subActiveStep}/02`}</span>}
        {activeStep !== 0 && <span className="stepperText">{`0${activeStep}`}.</span>}
        <span className="stepperText">&nbsp; {text}</span>
      </div>
      <div className="slider">
        <MuiStepper activeStep={activeStep} />
        {/*<Stepper
          steps={[1,2,3,4,5,6]}
          activeStep={activeStep}
          activeColor="#EB7078"
          defaultColor="#eee"
          completeColor="#c7202c"
          activeTitleColor="#000"
          circleFontColor="#663300"
          completeBarColor="#c7202c"
          size={10}
          circleFontSize={0}
          circleTop={0}
          activeTitleColor="fff"
          circleFontColor="fff"
        />*/}
      </div>
    </div>
    )
}
