import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { removeAdmin } from '../Actions/admin.actions';
import { removeFeedbackData } from '../Actions/feedback.actions';
import { removeCustomerData } from '../Actions/customer.actions';
import {useDispatch} from 'react-redux';

const Logout = () => {
  //other logic
  const navigate = useNavigate();
  const removeAdminAction = removeAdmin;
  const removeFeedbackDataAction = removeFeedbackData;
  const removeCustomerDataAction = removeCustomerData
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(removeAdminAction());
    dispatch(removeFeedbackDataAction());
    dispatch(removeCustomerDataAction());
  
    localStorage.removeItem("PCC_crm");
    localStorage.removeItem("PCC_crm_version");
    localStorage.removeItem("Device_Legal_Entity");
    localStorage.removeItem("isFeedbackFromPOS");
    localStorage.removeItem("Device_POS_ID");
    navigate("/login");
  }, []);

  return (
    <>
    </>

  );
};

export default Logout;