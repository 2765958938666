import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import FloatSelect from '../../components/FloatSelect';
import FloatTextField from '../../components/FloatTextField';
import {Salutation, Days, Months, generateYears, getDate, getMonth, getYear} from '../../utils/utils';
import DatePickerField from '../../components/DatePickerView';


const Children = (props) => {
	const {item, index = 0, handleChildChange, handleChildBirthdayChange} = props;
	const {t} = useTranslation();

	return (
		<>
			<div className="my-1 row" key={index}>
				<div className="col-lg-4 col-md-4 my-2">
                  	<FloatSelect label={t("salutation")} name="child_gender" list={Salutation} value={item.child_gender} onInputChange = {(e) => handleChildChange(e, index)} />
					</div>
                <div className="col-lg-4 col-md-4 my-2">
                    <FloatTextField label={t("child_name")} name="child_name" value={item.child_name} onInputChange = {(e) => handleChildChange(e, index)} />
                </div>

                <div className="col-lg-4 col-md-4 my-2">
                    <DatePickerField label="Birthday" value={item.child_dob} name="child_dob" onInputChange = {(val, name) => handleChildBirthdayChange(val, index)} />
				</div>
			</div>
		</>
	)
}

export default Children;