import React from "react";
import ReactDOM from "react-dom";
import {Circles} from 'react-loader-spinner';

//import Loader from "react-loader";

import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from 'react-i18next';
//import BounceLoader from "react-spinners/BounceLoader";

import styled, { css } from "styled-components";

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */

  ${props =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

function Loading(props) {

  const {t} = useTranslation();
  const {text = t('loading_title')} = props;

  return (
      <DarkBackground disappear={true}>
        <LoadingOverlay
          active={true}
          
          text={<Circles
            color="#D38C33"
            height={70}
            width={70}
            className="mt-4 centerElement"
         />}
        >
        
        </LoadingOverlay>
      </DarkBackground>
  
  );
}
export default Loading;