import React, {useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {formatDate} from '../utils/utils';
import FloatTextField from '../components/FloatTextField';
import FullDialog from '../components/FullDialog';
import CustomizedSnackbars from '../components/CustomizedSnackbars';
import Loading from '../components/Loading';

import {removeCustomerData } from '../Actions/customer.actions';
import { removeFeedbackData } from '../Actions/feedback.actions';

const ThankYou = (props) => {
  const { userData={}, formData = {}, setFormData, onPageIndex } = props;
  const removeCustomerDataAction = removeCustomerData;
  const removeFeedbackDataAction = removeFeedbackData;

  const isFeedbackFromPOS = localStorage.getItem("isFeedbackFromPOS");
  const stored_LE = localStorage.getItem("Device_Legal_Entity");
  const stored_POS = localStorage.getItem("Device_POS_ID");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isFormValid, setFormValid] = useState(true);
  const [isINVRequired, setINVRequired] = useState(false);
  //const [isVocherCodeRequired, setVoucherCodeRequired] = useState(false);

  const initialFormError = {
    invoicenoError : false,
    vouchercodeError : false,
    salesNameError : false
  };

  const [formErrors, setFormErrors] = useState(initialFormError);
  const store_legal_Entity_id = localStorage.getItem("Device_Legal_Entity");

  const {
    details,
    loading,
    error
  } = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));

  const handleChange = (e) => {
    setFormData({...formData, admin_details: { ...formData.admin_details, [e.target.name] : e.target.value}});
  }
  
  const onHandleAlert = () => {
    setError(false);
  }

  const validate = () => {
    let isFormValid = true;
    let isInvalidDateTimeError = false;
    let isInvalidInvoiceError = false;
    let isInvalidVoucherError = false;
    let isInvalidSalesNameError = false;

    if(invoiceno === "") {
      isInvalidInvoiceError = true;
      isFormValid = false;
    }
    
    /*  
    if(voucher_code === "") {
      isInvalidVoucherError = true;
      isFormValid = false;
    }
    */

    if(salespersonname === "") {
      isInvalidSalesNameError = true;
      isFormValid = false;
    }

    setFormErrors({...formErrors,
      invoicenoError : isInvalidInvoiceError,
      vouchercodeError : isInvalidVoucherError,
      salesNameError : isInvalidSalesNameError
    });

    setFormValid(isFormValid);
    return isFormValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validate();

    if(isFormValid) {
      if(formData.datetimestamp === 0) {
          postFeedback();
      } else {
        updateFeedback();
      }
    }
  }

  const postFeedback = async () =>  {
    setLoading(true);
    const URL = "https://api.smmc.dev:8908/smmc-pcchandra/addfeedback";
    
    /*formData.datetimestamp = formatDate(new Date());*/

    const d = new Date();
    formData.datetimestamp = d.getTime(); 
    formData.legal_entity_id = store_legal_Entity_id;  

    await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(formData) // body data type must match "Content-Type" header
    })
    .then(res => res.json())
    .then((res) => {
      setLoading(false);
      setError(false);
      setOpen(true);
      SendSuccessFeedbackSMS();
    })
    .catch((error) => {
      console.log('error', error);
      setLoading(false);
      setError(true);
    });
  }

  const updateFeedback = async () =>  {
    setLoading(true);

    const URL = "https://api.smmc.dev:8908/smmc-pcchandra/updatefeedback";
    /*formData.datetimestamp = formatDate(new Date());*/
    
    const d = new Date();
    formData.datetimestamp = d.getTime();
    formData.legal_entity_id = store_legal_Entity_id;

    await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(formData) // body data type must match "Content-Type" header
    })
    .then(res => res.json())
    .then((res) => {
      setLoading(false);
      setError(false);
      setOpen(true);
      SendSuccessFeedbackSMS();
    })
    .catch((error) => {
      console.log('error', error);
      setLoading(false);
      setError(true);
    });
  }

  const SendSuccessFeedbackSMS = async () => {
    const URL = "https://api.smmc.dev:8908/smmc-pcchandra/sendFeedbackSuccessSms";

    const obj = {};
    obj.sms_pos_id = stored_POS;
    obj.mobiles = `91${formData.customer_phone}`;
    obj.customer_name = `${formData.customer_name}`;
    obj.voucher_code = voucher_code === "" ? invoiceno : voucher_code;

    //console.log(obj);

    await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(obj) // body data type must match "Content-Type" header
    })
    .then(res => res.json())
    .then((res) => {
      
    })
    .catch((error) => {
      console.log('error', error);
      setError(true);
    });

  }

  useEffect(() => {
    if (!isFormValid) {
      setFormValid(true);
    }
  }, [isFormValid]);

  useEffect(() => {
    if(invoiceno === "") {
      setINVRequired(true);
    }
    /*if(voucher_code === "") {
      setVoucherCodeRequired(true);
    }*/
    const headerEle = document.querySelector("header");
    if (headerEle) {
      headerEle.scrollIntoView({top: 0,behavior: "smooth"});
    }
  }, []);

  useEffect(() => {
    if(details.emp_id) {
      setFormData({...formData, admin_details: { ...formData.admin_details, ["pos"] : details.emp_base_office_id}});
      setFormData({...formData, legal_entity_id : details.legal_entity_id});
    }
  }, [details]);

  const onDialogClose = () => {
    resetStates();
    //Navigate to login page
    if (isFeedbackFromPOS === "true") {
      navigate("/");
    }
  }

  const resetStates = () => {
    dispatch(removeCustomerDataAction());
    dispatch(removeFeedbackDataAction());
    /*localStorage.removeItem("isFeedbackFromPOS");*/
  }

  const handleDetailsChange = (e) => {
    setFormData({...formData, feedback_details: {...formData.feedback_details, [e.target.name] : e.target.value}});
  }

  const handleAdminChange = (e) => {
    setFormData({...formData, admin_details: {...formData.admin_details, [e.target.name] : e.target.value}});
  }

  const handleBack = () => {
    onPageIndex(6);
  }

  const {
    invoicenoError = false,
    vouchercodeError = false,
    salesNameError = false
  } = formErrors;

  useEffect(() => {
    if (!isFormValid) {
      const firstInvalidEle = document.querySelector('[aria-invalid="true"]');
      if (firstInvalidEle) firstInvalidEle.focus();
      setFormValid(true);
    }
  }, [isFormValid]);

  const { 
    datetimestamp = 0,
  } = formData;

  const { 
    salespersonname = ""
  } = formData?.feedback_details;

  const { 
    pos = "",
    invoiceno = "",
    voucher_code = ""
  } = formData?.admin_details;

  return (
    <div>
      <form className="px-lg-3" onSubmit={(e) => handleSubmit(e)} noValidate>
        <h1 className="py-2 heading">{t('purchase_details')}</h1>
        <div className="container-fuild">
          <div className="row">
            <div className="col-12">
              <div className="mb-2">
                <div className="py-3">
                  <FloatTextField isError={vouchercodeError} label={t("voucher_code")} isDisabled={isFeedbackFromPOS === "false"} value={voucher_code} name="voucher_code" isRequired={false} onInputChange={(e) => handleAdminChange(e)} />
                  {vouchercodeError && <div className="text-danger fw-bold error_text">! {t("send_link_voucher_code_error_message")}</div>}
                </div>

                <div className="py-3">
                  <FloatTextField isError={invoicenoError} label={t("invoice_no")} isDisabled={isFeedbackFromPOS === "false"} value={invoiceno} name="invoiceno" isRequired={isINVRequired} onInputChange={(e) => handleAdminChange(e)} />
                  {invoicenoError && <div className="text-danger fw-bold error_text">! {t("send_link_invoice_number_error_message")}</div>}
                </div>
                
                <div className="py-3">
                	<FloatTextField isError={salesNameError} label={t("customer_executive_name")} value={salespersonname} name="salespersonname" isRequired={true} onInputChange={(e) => handleDetailsChange(e)} />
                  {salesNameError && <div className="text-danger fw-bold error_text">! {t("send_link_sales_name_error_message")}</div>}
                </div>
                
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end pt-4 px-3 px-lg-3">
          <button type="button" className="btn btn-outline-danger px-5 mx-4" onClick={handleBack}>
            <span>{t("back")}</span>
          </button>
        	<button type="submit" className="btn btn-danger px-5 thank_Submit_btn">{t("Submit")}</button>
        </div>
      </form>
      {isOpen && <FullDialog isOpen={isOpen} onHandleCloseDialog={onDialogClose} />}
      {isError && <CustomizedSnackbars isShow={isError} text={t("server_error")} showAlert={(isHide) => onHandleAlert(isHide)} />}
      {isLoading && <Loading/>}
    </div>
  )
}

export default ThankYou;