const AmbienceData = {
    "title" : "ambience_heading",
    "fields" : [
        {"title" : "ambience_cleanliness", "name" : "ambienceCleanliness", "icon": "cleaniless.png"},
        {"title" : "ambience_decor", "name" : "ambienceDecor", "icon": "Decor.png"},
        {"title" : "ambience_lighting", "name" : "ambienceLighting", "icon": "Lighting.png"},
        {"title" : "ambience_music", "name" : "ambienceMusic", "icon": "Music.png"}
    ]
};

const ProductData = {
    "title" : "product_heading",
    "fields" : [
        {"title" : "product_display_gold", "icon": "gold_jewellery.png"},
        {"title" : "product_display_diamond", "icon": "Dimond.png"},
        {"title" : "product_Design", "icon": "design.png"},
        {"title" : "product_price_range", "icon": "priceRange.png"},
        {"title" : "product_avialability", "icon": "availability.png"}
    ]
};

const ServiceData = {
    "title" : "service_heading",
    "fields" : [
        {"title" : "service_sales_courtesy", "icon": "courtesy.png"},
        {"title" : "service_billing_procedure", "icon": "billlingProcedure.png"},
        {"title" : "service_old_gold_exchange", "icon": "cleaniless.png"}
    ]
};

const HearUSData = {
    "title" : "source_heading",
    "fields" : [
        {"title" : "source_social_media", "value": "Social Media", "img": "social.png"},
        {"title" : "source_newspaper",  "value": "News Paper",  "img": "news.png"},
        {"title" : "source_TV",  "value": "TV",  "img": "tv.png"},
        {"title" : "source_advertisement", "value": "Advertisement", "img": "AD.png"},
        {"title" : "source_radio", "value": "Radio", "img": "radio.png"},
        {"title" : "source_other", "value": "Other", "img": "User.png"}
    ]
};

const PreferMediaData = {
    "title" : "prefer_media_heading",
    "fields" : [
        {
            "title" : "prefer_media_TV",
            "value" : "TV",
            "icon": "Star_jalsa",
            "name": "",
            "list" : [
                {
                    "title" : "Star Jalsa",
                    "value" : "Star Jalsa",
                    "img" : "star.png"
                },
                {
                    "title" : "Zee Bangla",
                    "value" : "Zee Bangla",
                    "img" : "zee.png"
                },
                {
                    "title" : "R Bangla",
                    "value" : "R Bangla",
                    "img" : "R.png"
                },
                {
                    "title" : "24 Ghanta",
                    "value" : "24 Ghanta",
                    "img" : "24.png"
                },
                {
                    "title" : "Abp Ananda ",
                    "value" : "Abp Ananda",
                    "img" : "ananda.png"
                }
            ]
        },
        {
            "title" : "prefer_media_radio",
            "value" : "Radio",
            "icon": "Star_jalsa",
            "list" : [
                {
                    "title" : "Red FM",
                    "value" : "Red FM",
                    "img" : "red_fm.png"
                },
                {
                    "title" : "Big FM",
                    "value" : "Big FM",
                    "img" : "big_fm.png"
                }
            ]
        },
        {
            "title" : "prefer_media_online_DVD", 
            "value" : "Online DVD", 
            "icon": "Star_jalsa",
            "list" : [
                {
                    "title" : "DVD",
                    "value" : "DvD",
                    "img": "dvd.png"
                }
            ]
        },
        {
            "title" : "prefer_media_newspaper",
            "value" : "News Paper",
            "icon": "Star_jalsa",
            "list" : [
                {
                    "title" : "Ananda Bazar",
                    "value" : "Ananda Bazar",
                    "img": "anandabazar.png"
                },
                {
                    "title" : "Ajkal",
                    "value" : "Ajkal",
                    "img": "ajkal.png"
                },
                {
                    "title" : "Pratidin",
                    "value" : "Pratidin",
                    "img": "protidin.png"
                },
                {
                    "title" : "Ebela",
                    "value" : "Ebela",
                    "img": "ebela.png"
                },
                {
                    "title" : "Eisomoi",
                    "value" : "Eisomoi",
                    "img": "eisomoi.png"
                }
            ]
        },
        {
            "title" : "prefer_media_movie_theatre",
            "value" : "Movie Theatre",
            "list" : [
                {
                    "title" : "PVR",
                    "value" : "PVR",
                    "img": "theatre1.png"

                },
                {
                    "title" : "INOX",
                    "value" : "INOX",
                    "img": "theatre2.png"
                },
                {
                    "title" : "IMAX",
                    "value" : "IMAX",
                    "img": "theatre3.png"
                }
            ]
        }
    ]
};

const Ranchi_PreferMediaData = {
    "title" : "prefer_media_heading",
    "fields" : [
        {
            "title" : "prefer_media_TV",
            "value" : "TV",
            "icon": "Star_jalsa",
            "name": "",
            "list" : [
                {
                    "title" : "News 18",
                    "value" : "News 18",
                    "img" : "news_18.png"
                },
                {
                    "title" : "News 11",
                    "value" : "News 11",
                    "img" : "news_11.jpeg"
                },
                {
                    "title" : "Zee Bihar Jharkhand",
                    "value" : "Zee Bihar Jharkhand",
                    "img" : "zee_bihar_jharkand.jpeg"
                }
            ]
        },
        {
            "title" : "prefer_media_radio",
            "value" : "Radio",
            "icon": "Star_jalsa",
            "list" : [
                {
                    "title" : "Big FM",
                    "value" : "Big FM",
                    "img" : "bigfm.jpeg"
                },
                {
                    "title" : "Radio city",
                    "value" : "Radio city",
                    "img" : "radio_city.jpeg"
                },
                 {
                    "title" : "Radio Dhoom",
                    "value" : "Radio Dhoom",
                    "img" : "radio_dhoom.jpeg"
                }
            ]
        },
        {
            "title" : "prefer_media_online_DVD", 
            "value" : "Online DVD", 
            "icon": "Star_jalsa",
            "list" : [
                {
                    "title" : "DVD",
                    "value" : "DvD",
                    "img": "dvd.png"
                }
            ]
        },
        {
            "title" : "prefer_media_newspaper",
            "value" : "News Paper",
            "icon": "Star_jalsa",
            "list" : [
                {
                    "title" : "Prabhat khabar",
                    "value" : "Prabhat khabar",
                    "img": "prabhat_khabar.png"
                },
                {
                    "title" : "Dainik jagaran",
                    "value" : "Dainik jagaran",
                    "img": "dainik_jagaran.jpeg"
                },
                {
                    "title" : "Dainik bhaskar",
                    "value" : "Dainik bhaskar",
                    "img": "dainik_bhaskar.png"
                },
                {
                    "title" : "Hindustan",
                    "value" : "Hindustan",
                    "img": "hindustan.jpeg"
                },
            ]
        },
        {
            "title" : "prefer_media_movie_theatre",
            "value" : "Movie Theatre",
            "list" : [
                {
                    "title" : "PVR",
                    "value" : "PVR",
                    "img": "theatre1.png"

                },
                {
                    "title" : "P.J.P",
                    "value" : "P.J.P",
                    "img": "pjp.jpeg"
                },
                {
                    "title" : "Fun cinema",
                    "value" : "Fun cinema",
                    "img": "fun_cinemas.jpeg"
                },
                {
                    "title" : "Miraj",
                    "value" : "Miraj",
                    "img": "miraj_cinnemas.jpeg"
                }
            ]
        }
    ]
};

const PreferSocialMediaData = {
    "title" : "prefer_social_media_heading",
    "fields" : [
        {"title" : "perfer_media_facebook",  "value": "Facebook", "img": "facebook.png"},
        {"title" : "perfer_media_instragram", "value": "Instagram", "img": "insta.png"},
        {"title" : "perfer_media_twitter", "value": "Twitter", "img": "twitter.png"},
        {"title" : "perfer_media_linkedin", "value": "LinkedIn",  "img": "linkedin.png"},
        {"title" : "perfer_media_NA", "value": "Other", "img": "User.png"}
    ]
};

const PreferMovieData = ["INOX", "PVR"];

export {HearUSData, AmbienceData, ProductData, ServiceData, PreferMediaData, PreferSocialMediaData, PreferMovieData, Ranchi_PreferMediaData};