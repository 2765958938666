import React, {useEffect, useState} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function FloatSelect(props) {
  const [age, setAge] = React.useState('');
  const {isError = false, classVal = "", label = "", name="", isRequired = false, list = [], value="", onInputChange} = props;

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
    onInputChange(event);
  };

  useEffect(() => {
    if(value) setAge(value);
  }, [value]);

  return (
    <div className="select">
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} error={isError} required={isRequired} >
        <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={age}
          onChange={handleChange}
          label={label}
          name={name}
          className={`${classVal}`}
        >
          <MenuItem value="" disabled>
            <em>None</em>
          </MenuItem>

          {list.map((item, index) => {
            return <MenuItem value={item.value}>{item.label}</MenuItem>
          })}
        </Select>
      </FormControl>
      
    </div>
  );
}
