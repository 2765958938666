import {
  GET_ADMIN_START,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_FAILURE,
  REMOVE_LOGGEDIN_ADMIN,
  baseURL
} from '../../Actions/Constants';
import {removeAllFields} from '../../utils/utils';
   
const defaultState = {
  details: {},  //[]
  error: null,
  loading: false
}; 
 
export default function adminReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_ADMIN_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ADMIN_SUCCESS:
      return {
        ...state,
        details: action.payload,
        error: null,
        loading: false
      };
    case GET_ADMIN_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case REMOVE_LOGGEDIN_ADMIN:
      return {
        ...state,
        details: removeAllFields(state?.details),
        error: action.error,
        loading: false
      };
      
    default:
      return state;
  }
}