import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import SliderRating from '../../components/SliderRating';
import RadioView from '../../components/RadioView';
import FooterControls from '../../components/FooterControls';
import {ServiceData} from '../../assets/Data.js';
import design from '../../assets/design.png';
import courtesy from '../../assets/courtesy.png';
import billlingProcedure from '../../assets/billlingProcedure.png';
import FloatTextField from '../../components/FloatTextField';

const Service1 = (props) => {
  const {t} = useTranslation();
  const { formData={}, setFormData , onPageIndex} = props;
  const [isFormValid, setFormValid] = useState(true);
  const [isError, setError] = useState(false);

  const handleChange = (e) => {
    setFormData({...formData, feedback_details: {...formData.feedback_details, [e.target.name] : e.target.value}});
  }
  
  const {
    f_salesstaffservice = "",
    f_billingprocedureservice = "",
    f_goldexchangeservice = "",
    servicecomments = "",
  } = formData.feedback_details;

  return (
    <>
      <div className="bg-light rounded px-3 py-2 bg-white-color my-3">
        <SliderRating icon = {courtesy} indexVal="01" label={t(ServiceData.fields[0].title)} name="f_salesstaffservice" value={f_salesstaffservice} onInputChange={(e) => handleChange(e)} />
      </div>

      <div className="bg-light rounded px-3 py-2 bg-white-color my-3">
        <SliderRating icon= {billlingProcedure} indexVal="02" label={t(ServiceData.fields[1].title)} name="f_billingprocedureservice" value={f_billingprocedureservice} onInputChange={(e) => handleChange(e)} />
      </div>
      
      <div className="bg-light rounded px-3 py-2 bg-white-color my-3">
        <SliderRating icon={design} indexVal="03" label={t(ServiceData.fields[2].title)} name="f_goldexchangeservice" value={f_goldexchangeservice} onInputChange={(e) => handleChange(e)} />
      </div>

      {/*
      <div className="py-3 rounded px-3">
        <div>
          <FloatTextField label={t("service_any_other_comments")} value={servicecomments} name="servicecomments"  onInputChange={(e) => handleChange(e)} />
        </div>
      </div>
      */}
	  </>
  );
}

export default Service1;