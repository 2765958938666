import React, {useEffect, useState} from 'react';
import SliderRating from '../../components/SliderRating';
import { useTranslation } from 'react-i18next';
import {ProductData} from '../../assets/Data.js';
import RadioView from '../../components/RadioView';
import Cleaniless from '../../assets/Cleaniness.png';
import gold from '../../assets/gold_jewellery.png';
import design from '../../assets/design.png';
import Dimond from '../../assets/Dimond.png';
import priceRange from '../../assets/priceRange.png';
import availability from '../../assets/availability.png';


const ProductRating1 = (props) => {
	const {t} = useTranslation();
  	const { formData={}, setFormData} = props;
  	
  	const handleChange = (e) => {
    	setFormData({...formData, feedback_details: {...formData.feedback_details, [e.target.name] : e.target.value}});
  	}

  	const {
  		f_productrategold = "",
      f_productratediamond = "",
      f_productratedesign = "",
      f_productrateprice = "",
      f_productrateavailibility = ""
  	} = formData.feedback_details;

	return (
	  <>
  		<div className="bg-light rounded px-3 py-2 bg-white-color my-3">
        <SliderRating icon={gold} indexVal="01" label={t(ProductData.fields[0].title)} name="f_productrategold" value={f_productrategold} onInputChange={(e) => handleChange(e)} />
      </div>

    	<div className="bg-light rounded px-3 py-2 bg-white-color my-3">
      	<SliderRating icon={Dimond} indexVal="02" label={t(ProductData.fields[1].title)} name="f_productratediamond" value={f_productratediamond} onInputChange={(e) => handleChange(e)} />
    	</div>

    	<div className="bg-light rounded px-3 py-2 bg-white-color my-3">
      	<SliderRating icon={design} indexVal="03" label={t(ProductData.fields[2].title)} name="f_productratedesign" value={f_productratedesign} onInputChange={(e) => handleChange(e)} />
    	</div>

      <div className="bg-light rounded px-3 py-2 bg-white-color my-3">
        <SliderRating icon={priceRange} indexVal="04" label={t(ProductData.fields[3].title)} name="f_productrateprice" value={f_productrateprice} onInputChange={(e) => handleChange(e)} />
      </div>

      <div className="bg-light rounded px-3 py-2 bg-white-color my-3">
        <SliderRating icon={availability} indexVal="05" label={t(ProductData.fields[4].title)} name="f_productrateavailibility" value={f_productrateavailibility} onInputChange={(e) => handleChange(e)} />
      </div>
	  </>
	)
}

export default ProductRating1;