import React, {useEffect, useState} from 'react';
import SliderRating from '../../components/SliderRating';
import { useTranslation } from 'react-i18next';
import {AmbienceData} from '../../assets/Data.js';
import RadioView from '../../components/RadioView';
import Cleaniless from '../../assets/Cleaniness.png';
import Lighting from '../../assets/Lighting.png';
import Music from '../../assets/Music.png';
import Decor from '../../assets/Decor.png';

const Ambience1 = (props) => {
	const {t} = useTranslation();
  	const { formData={}, setFormData} = props;
  	
  	const handleChange = (e) => {
    	setFormData({...formData, feedback_details: {...formData.feedback_details, [e.target.name] : e.target.value}});
  	}

  	const {
		f_ambiencecleanliness = "",
	 	f_ambiencedecor = "",
	 	f_ambiencelighting = "",
	 	f_ambiencemusic = ""
  	} = formData.feedback_details;

	return (
	<>
		<div className="bg-light rounded px-3 py-1 bg-white-color">
            <SliderRating icon={Cleaniless} indexVal="01" label={t("ambience_cleanliness")} name="f_ambiencecleanliness" value={f_ambiencecleanliness} onInputChange={(e) => handleChange(e)} />
		</div>

		<div className="bg-light rounded px-3 py-1 bg-white-color my-3">
            <SliderRating icon={Decor} indexVal="02" label={t("ambience_decor")} name="f_ambiencedecor" value={f_ambiencedecor} onInputChange={(e) => handleChange(e)} />
		</div>

		<div className="bg-light rounded px-3 py-1 bg-white-color my-2">
            <SliderRating  icon={Lighting} indexVal="03" label={t("ambience_lighting")} name="f_ambiencelighting" value={f_ambiencelighting} onInputChange={(e) => handleChange(e)} />
		</div>

		<div className="bg-light rounded px-3 py-1 bg-white-color my-2">
            <SliderRating  icon={Music} indexVal="04" label={t("ambience_music")} name="f_ambiencemusic" value={f_ambiencemusic} onInputChange={(e) => handleChange(e)} />
		</div>
	</>
	)
}

export default Ambience1;