import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import Customer_Experience_card from "../../assets/Customer_Experience_card.png";

const VoucherUpdateDialog = (props) => {
  const {isOpen, onHandleCloseDialog, invoice = "", voucher = ""} = props;
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  const {t} = useTranslation();

  const handleClose = () => {
    setOpen(false);
    onHandleCloseDialog(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <React.Fragment>
      <Dialog 
        onClose={handleClose} 
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        aria-label=""
        
        >
        <div className="px-4 py-2 ">
          <div className="d-flex justify-content-center">
            <img src={Customer_Experience_card} className="thank_you_image" alt="" />
          </div>

          <div className="d-flex flex-column justify-content-center text-center dialog">
            <div className="Roboto font24">Voucher code {voucher} is updated for {invoice}.</div>
          </div>

          <div className="my-4 m-auto d-flex justify-content-center">
            <Link to="/" className="btn btn-danger me-3" onClick={handleClose}>{t("Home")}</Link>
            <button className="btn btn-outline-danger" onClick={handleClose}>{t("close")}</button>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

export default VoucherUpdateDialog;
