import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import CardCheckBox from '../components/CardCheckBox';
import CustomizedSnackbars from '../components/CustomizedSnackbars';
import FloatTextField from '../components/FloatTextField';
import FooterControls from '../components/FooterControls';
import {HearUSData, PreferSocialMediaData} from '../assets/Data.js';
import {containerVariants} from '../utils/utils';

const PreferMedia = (props) => {
  const { formData={}, setFormData, onPageIndex } = props;
  const [isFormValid, setFormValid] = useState(true);
  const [isError, setError] = useState(false);
  const data = formData.feedback_details.preferredmovietheatre;
  const intialData = data ? data.split(",") : [];
  const {t} = useTranslation();

  const [movieTheater, setMovieTheater] = useState(intialData);

  /*
  const handleChange = (e) => {
    setFormData({...formData, feedback_details: {...formData.feedback_details, [e.target.name] : e.target.value}});
  }
  */
  
  const handleChange = (e) => {
    const { value } = e.target;
    let text = "";

    var list = formData.feedback_details[e.target.name];
    if(!list.includes(value)) {
      list.push(value)
    } else {
      const newList = list.filter((item, i) => list[i]  !== value);
      list = newList;
    }

    setFormData({...formData, feedback_details: {...formData.feedback_details, [e.target.name] : list}});
  }

  const handleFieldChange = (e) => {
    setFormData({...formData, feedback_details: {...formData.feedback_details, [e.target.name] : e.target.value}});
  }

  const onHandleAlert = () => {
    setError(false);
  }

  const validate = (e) => {
    let isFormValid = true;

    /*if(hearfrom === "") {
      isFormValid = false;
    } else if(preferredsocialmedia === "") {
      isFormValid = false;
    }
    */
    
    setFormValid(isFormValid);
    return isFormValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validate();
    if(isFormValid) {
      setError(false);
      onPageIndex(6);
    }
  }

  const handleBack = () => {
    onPageIndex(4);
  }

  useEffect(() => {
    if (!isFormValid) {
      setFormValid(true);
      setError(true);
    }
  }, [isFormValid]);

  useEffect(() => {
    const headerEle = document.querySelector("header");
    if (headerEle) {
      headerEle.scrollIntoView({top: 0, behavior: "smooth"});
    }
  }, []);

  const isFieldShow = (targetList = []) => {
    return targetList.includes("Other");
  }

  const {
    hearfrom = [],
    otherhearfrom = "",
    otherpreferredsocialmedia = "",
    preferredsocialmedia = [],
  } = formData.feedback_details;

  return (
    <div className="container-fluid">
      <form className="px-lg-3" onSubmit={(e) => handleSubmit(e)} noValidate>
        <h1 className="heading py-3">{t(HearUSData.title)}</h1>
        <div className="wrapper card-wrapper">
          {HearUSData.fields.map((item) => {
            const isChecked = hearfrom.includes(item.value);
            return (
              <CardCheckBox isChecked={isChecked} item={item} name="hearfrom" onInputChange = {(e) => handleChange(e)} />
            )})
          }
        </div>
        
        {isFieldShow(hearfrom) && <div className="col-12 mt-1">
          <FloatTextField name="otherhearfrom" label={t("other_source")} value={otherhearfrom} onInputChange = {(e) => handleFieldChange(e)} />
        </div>}

        <h1 className="heading pt-5 pb-3">{t(PreferSocialMediaData.title)}</h1>
        <div className="wrapper card-wrapper">
          {PreferSocialMediaData.fields.map((item) => {
            const isChecked = preferredsocialmedia.includes(item.value);
            return (
              <CardCheckBox isChecked={isChecked} item={item} name="preferredsocialmedia" onInputChange = {(e) => handleChange(e)} />
            )})
          }
        </div>
        
        {isFieldShow(preferredsocialmedia) && <div className="col-12 mt-1">
          <FloatTextField name="otherpreferredsocialmedia" label={t("other_social_source")} value={otherpreferredsocialmedia} onInputChange = {(e) => handleFieldChange(e)} />
        </div>}

        <FooterControls onBackBtnClick={handleBack} />
      </form>
      {isError && <CustomizedSnackbars isShow={isError} text={t("Choose_one")} showAlert={(isHide) => onHandleAlert(isHide)} />}
    </div>
  );
}

export default PreferMedia;