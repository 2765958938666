import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './i18n';
import './index.css';
import App from './App';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import Popper from 'popper.js';
import 'bootstrap/dist/css/bootstrap.css';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './Reducers';
import thunk from 'redux-thunk';

import './Fonts/OpenSans-Regular.ttf';
import './Fonts/RobotoSlab-Regular.ttf';
import './Fonts/LibreBodoni-Regular.ttf';

function saveToSessionStorage(state) {
	try {
		//const {loggedAdmin:newLoggedAdmin, currentFilters:newFilters} = state;
		//const obj = {};
		//obj.loggedAdmin = newLoggedAdmin;
		//obj.currentFilters = newFilters;
		
		const serializedState = JSON.stringify(state); //state
		localStorage.setItem('PCC_crm', serializedState);
	}
	catch {
		console.log('error');
	}
}

function loadFromLocalStoarge() {
	try {
		//localStorage.removeItem('state');
		const serializedState = localStorage.getItem('PCC_crm');
		if (serializedState === null) return undefined;
		return JSON.parse(serializedState);	
	} catch(e) {
		return undefined;
	}
}

const persistedState = loadFromLocalStoarge();
const storeEnhancer = compose(
    applyMiddleware(thunk),
    /*window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()*/
);

export const store = createStore(
	rootReducer,
	persistedState,
	storeEnhancer,
);

// If you want to start measuring performance in your app, pass a function
store.subscribe(()=> saveToSessionStorage(store.getState()));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Router>
  	<Provider store={store}>
    	<App />
    </Provider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals



