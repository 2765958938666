export const GET_ADMIN_START = "GET_ADMIN_START";
export const GET_ADMIN_FAILURE = "GET_ADMIN_FAILURE";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";

export const SAVE_LOGGEDIN_ADMIN = "SAVE_LOGGEDIN_ADMIN";
export const GET_LOGGEDIN_ADMIN   = "GET_LOGGEDIN_ADMIN";
export const REMOVE_LOGGEDIN_ADMIN   = "REMOVE_LOGGEDIN_ADMIN";

export const SET_CUSTOMER_DATA = "SET_CUSTOMER_DATA";

export const GET_CUSTOMER_START  = "GET_CUSTOMER_START";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAILURE = "GET_CUSTOMER_FAILURE";

export const SAVE_CUSTOMER_DATA = "SAVE_CUSTOMER_DATA";
export const GET_CUSTOMER_DATA   = "GET_CUSTOMER_DATA";
export const REMOVE_CUSTOMER_DATA   = "REMOVE_CUSTOMER_DATA";

export const ADD_FEEDBACK_START = "ADD_FEEDBACK_START";
export const ADD_FEEDBACK_SUCCESS = "ADD_FEEDBACK_SUCCESS";
export const ADD_FEEDBACK_FAILURE = "ADD_FEEDBACK_FAILURE";
export const REMOVE_FEEDBACK_DATA = "REMOVE_FEEDBACK_DATA";

export const SET_FEEDBACK_DATA = "SET_FEEDBACK_DATA";

export const GET_FEEDBACK_START = "GET_FEEDBACK_START";
export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS";
export const GET_FEEDBACK_FAILURE = "GET_FEEDBACK_FAILURE";

export const PCC_crm_version = '1.0.1';
export const baseURL     = "https://api.smmc.dev:8908/smmc-pcchandra";

