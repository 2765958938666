import React, {useState} from 'react';
import i18n from 'i18next';
import {Language_Array} from '../utils/utils';
import Select from 'react-select';
import bengali from '../assets/bengali_selected.png';
import hindi from '../assets/hindi_selected.png';
import english from '../assets/english_selected.png';

const LanguageDropdown = () => {

	
	const data = [
    {
      value: 'en',
      text: 'English',
      icon: <img src={english} alt = "" style={{width: '20px'}} />
    },
    {
      value: 'bn',
      text: 'Bengali',
      icon: <img src={bengali} alt = "" style={{width: '20px'}} />
    },
    {
      value: 'hi',
      text: 'Hindi',
      icon: <img src={hindi} alt = "" style={{width: '20px'}} />
    }
  ];

  const [lang, setLang] = useState(data[0]);

	const onLanguageChange = (e) => {
		const {value} = e.target;
		setLang(value);
		i18n.changeLanguage(value);
	}

	const handleLanguageChange = (e) => {
		const lang = e.value;
    setLang(lang);
	  i18n.changeLanguage(lang);
  }


  const customStyles = value => ({
  control: (provided, state) => ({
    ...provided,
    alignItems: "baseline",
    backgroundColor: value ? "gray" : "white"
  })
});

	return(
		<div className="dropdown">
        <Select
        styles={customStyles}
        options={data}
        defaultValue = {lang}
        isSearchable = {false}
        onChange={(e) => handleLanguageChange(e)}
        getOptionLabel={e => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {e.icon}
            <span style={{ marginLeft: 5 }}>{e.text}</span>
          </div>
        )}
      />
      </div>
	)
}

export default LanguageDropdown;