import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import CustomizedSnackbars from '../components/CustomizedSnackbars';
import FooterControls from '../components/FooterControls';
import RadioView from '../components/RadioView';
import {ProductData} from '../assets/Data.js';
import {containerVariants} from '../utils/utils';
import ProductRating1 from './Products/ProductRating1';
import ProductRating2 from './Products/ProductRating2';

const Products = (props) => {
  const {t} = useTranslation();
	const { formData={}, setFormData , onPageIndex} = props;
  const [isFormValid, setFormValid] = useState(true);
  const [isError, setError] = useState(false);
  const [productRatingIndex, setProductRatingIndex] = useState(1);

  const onHandleAlert = () => {
    setError(false);
  }

  const validate = (e) => {
    let isFormValid = true;

    if(productRatingIndex === 1) {
      if(f_productrategold === "") {
        isFormValid = false;
      }

      if(f_productratediamond === "") {
        isFormValid = false;
      }

      if(f_productratedesign === "") {
        isFormValid = false;
      }

      if(f_productrateprice === "") {
        isFormValid = false;
      }

      if(f_productrateavailibility === "") {
        isFormValid = false;
      }
    }

    setFormValid(isFormValid);
    return isFormValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validate();

    if(isFormValid) {
      setError(false);
      onPageIndex(4);
    }
  }

  const handleBack = () => {
    onPageIndex(2);
  }

  useEffect(() => {
    if (!isFormValid) {
      setFormValid(true);
      setError(true);
    }
  }, [isFormValid]);

  useEffect(() => {
    const headerEle = document.querySelector("header");
    if (headerEle) {
      headerEle.scrollIntoView({top: 0,behavior: "smooth"});
    }
  }, []);

  const {
    f_productrategold = "",
    f_productratediamond = "",
    f_productratedesign = "",
    f_productrateprice = "",
    f_productrateavailibility = ""
  } = formData.feedback_details;

  return (
    <div>
      <form className="px-lg-3" onSubmit={(e) => handleSubmit(e)} noValidate>
        <h1 className="py-3 heading">{t(ProductData.title)}</h1>
        <div>
          {productRatingIndex === 1 && <ProductRating1 formData={formData} setFormData={setFormData} />}
          {productRatingIndex === 2 && <ProductRating2 formData={formData} setFormData={setFormData} />}
        </div>
        <FooterControls onBackBtnClick={handleBack} />
      </form>
      {isError && <CustomizedSnackbars isShow={isError} text={t("Choose_one")} showAlert={(isHide) => onHandleAlert(isHide)} />}
    </div>
  );
}

export default Products;

