import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';

import UserAddress from './UserInfo/UserAddress';
import UserSpouse from './UserInfo/UserSpouse';
import UserContact from './UserInfo/UserContact';
import UserChildren from './UserInfo/UserChildren';

import Stepper from '../components/Stepper';
import FooterControls from '../components/FooterControls';
import {isPhoneNumberValid, isEmailValid, initialUserFormErrorData} from "../utils/utils";
import {Countries, Gender, States, containerVariants} from '../utils/utils';

const UserInfo = (props) => {
  const {t} = useTranslation();
  const { setLabel, userData = {}, setUserData, onPageIndex, onUserStepperChange} = props;
  const [isShowInValidPhoneError, setShowInValidPhoneError] = useState(false);
  const [errors, setErrors] = useState(initialUserFormErrorData);
  const [userInfoIndex, setUserInfoIndex] = useState(1);
  const [isFormValid, setFormValid] = useState(true);
 
  const initialChildDetails = {
    child_name : "",
    child_gender : "",
    child_dob : ""
  };

  const handleUserInfoViewChange = (index) => {
    if(index <= 2) {
      setUserInfoIndex(index);
      onUserStepperChange(index);

    } else {
      onPageIndex(2)
    }
  }

  useEffect(() => {
    switch(userInfoIndex) {
      case 1 :
          setLabel("Feedback");
      case 2 : 
        setLabel("Address");
        break;
      case 3 : 
        setLabel("Family details");
        break;
      case 4 : 
        setLabel("Child details");
        break;
    }
  }, [userInfoIndex]);

  const handleChange = (e) => {
    setUserData({...userData, [e.target.name] : e.target.value});
  }

  const handleBirthDayUpdate = (val) => {
    setUserData({...userData, ["birthday"] : val});
  }

  const handleSpouseBirthDayUpdate = (val) => {
    setUserData({...userData, ["spouse_birthday"] : val});
  }

  const handleAnniversaryUpdate = (val) => {
    setUserData({...userData, ["anniversary"] : val});
  }

  const handleSpouseDetails = (e) => {
    setUserData({...userData, details: {...userData.details, [e.target.name] : e.target.value}});
  }

  const handleChildChange = (e, index) => {
    userData.details.children[index] = {...userData.details.children[index], [e.target.name]: e.target.value}
    setUserData({...userData});
  }

  const onhandleChildBirthdayChange = (value, index) => {
    userData.details.children[index] = {...userData.details.children[index], ["child_dob"]: value};
    setUserData({...userData});
  }

  const handleDateChange = (val , name) => {
    setUserData({...userData, [name] : val});
  }

  const handleSpouseDateChange = (val , name) => {
    setUserData({...userData, [name]: val});
  }

  const handleAddressChange = (e) => {
    setUserData({...userData, address: {...userData.address, [e.target.name] : e.target.value}});
  }

  const handleConsentChange = (e) => {
    setUserData({...userData, other_details: {...userData.other_details, [e.target.name] : e.target.checked}});
  }

  const addChild = () => {
    setUserData({...userData, ...userData.details.children.push(initialChildDetails)});
  }

  useEffect(() => {
    if (!isFormValid) {
      const firstInvalidEle = document.querySelector('[aria-invalid="true"]');
      if (firstInvalidEle) firstInvalidEle.focus();
      setFormValid(true);
    }
  }, [isFormValid]);

  useEffect(() => {
    const headerEle = document.querySelector("header");
    if (headerEle) {
      headerEle.scrollIntoView({top: 0, behavior: "smooth"});
    }
  }, []);

  const onHandleAlert = (isHide) => {
    setShowInValidPhoneError(false);
  }

  const {
    phone_no = "",
    pos_id = "",
    spouse_birthday = "",
    name = "",
    address = {
      city : "",
      state : "",
      country : "",
      pincode: "",
    },
    email = "",
    gender = "",
    birthday = "",
    anniversary = "",
    details = {
      spouse_name : "",
      children : [
        {
          child_name: "",
          child_dob: "",
          child_gender: ""
        }
      ]
    },
    other_details = {
      consent: "true"
    },
    legal_entity_id = ""
  } = userData;

  const {
    phoneError = false,
    nameError = false,
    emailError = false,
    cityError = false,
    stateError = false,
    pincodeError = false,
    birthdayError = false,
    countryError = false
  } = errors;

  return (
    <div>
      <div className="pt-2">
        {userInfoIndex === 1 && <UserContact onUserInfoViewChange={(index) => handleUserInfoViewChange(index)}   userData={userData}  handleChange={(e) => handleChange(e)} handleConsentChange = {(e) => handleConsentChange(e)} handleBirthDayUpdate={(val) => handleBirthDayUpdate(val)} handleAddressChange={(e) => handleAddressChange(e)} />}
        {userInfoIndex === 2 && <UserSpouse onUserInfoViewChange={(index) => handleUserInfoViewChange(index)}   userData={userData}  handleChange={(e) => handleChange(e)} handleSpouseDetails={(e) => handleSpouseDetails(e)} handleSpouseBirthDayUpdate = {(val) => handleSpouseBirthDayUpdate(val)} handleAnniversaryUpdate = {(val) => handleAnniversaryUpdate(val)} handleChildChange = {(e, index) => handleChildChange(e, index)} handleChildBirthdayChange = {(val, index) => onhandleChildBirthdayChange(val, index)}  addChild = {addChild} />}
      </div>
    </div>
  );
}

export default UserInfo;

 