import Cookie from 'js-cookie';

const Language_Array = [
	{
		"value": "en",
		"title": "English"
	},
	{
		"value": "bn",
		"title": "Bengali"
	},
	{
		"value": "hi",
		"title": "Hindi"
	}
];

const Salutation = [
    {
        "label" : "Mr",
        "value":  "Mr"
    },
    {
        "label" : "Mrs",
        "value":  "Mrs"
    },
    {
        "label" : "Ms", 
        "value":  "Ms"
    }
]

const Days = [
    {
        "label" : "01",
        "value":  "01"
    },
    {
        "label" : "02",
        "value":  "02"
    },
    {
        "label" : "03",
        "value":  "03"
    },
    {
        "label" : "04",
        "value":  "04"
    },
    {
        "label" : "05",
        "value":  "05"
    },
    {
        "label" : "06",
        "value":  "06"
    },
    {
        "label" : "07",
        "value":  "07"
    },
    {
        "label" : "08",
        "value":  "08"
    },
    {
        "label" : "09",
        "value":  "09"
    },
    {
        "label" : "10",
        "value":  "10"
    },
    {
        "label" : "11",
        "value":  "11"
    },
    {
        "label" : "12",
        "value":  "12"
    },
    {
        "label" : "13",
        "value":  "13"
    },
    {
        "label" : "14",
        "value":  "14"
    },
    {
        "label" : "15",
        "value":  "15"
    },
    {
        "label" : "16",
        "value":  "16"
    },
    {
        "label" : "17",
        "value":  "17"
    },
    {
        "label" : "18",
        "value":  "18"
    },
    {
        "label" : "19",
        "value":  "19"
    },
    {
        "label" : "20",
        "value":  "20"
    },
    {
        "label" : "21",
        "value":  "21"
    },
    {
        "label" : "22",
        "value":  "22"
    },
    {
        "label" : "23",
        "value":  "23"
    },
    {
        "label" : "24",
        "value":  "24"
    },
    {
        "label" : "25",
        "value":  "25"
    },
    {
        "label" : "26",
        "value":  "26"
    },
    {
        "label" : "27",
        "value":  "27"
    },
    {
        "label" : "28",
        "value":  "28"
    },
    {
        "label" : "29",
        "value":  "29"
    },
    {
        "label" : "30",
        "value":  "30"
    },
    {
        "label" : "31",
        "value":  "31"
    }
]

const Months = [
    {
        "label" : "January",
        "value":  "01"
    },
    {
        "label" : "February",
        "value":  "02"
    },
    {
        "label" : "March",
        "value":  "03"
    },
    {
        "label" : "April",
        "value":  "04"
    },
    {
        "label" : "May",
        "value":  "05"
    },
    {
        "label" : "June",
        "value":  "06"
    },
    {
        "label" : "July",
        "value":  "07"
    },
    {
        "label" : "August",
        "value":  "08"
    },
    {
        "label" : "September",
        "value":  "09"
    },
    {
        "label" : "October",
        "value":  "10"
    },
    {
        "label" : "November",
        "value":  "11"
    },
    {
        "label" : "December",
        "value":  "12"
    }
]

const Gender = [
	{
		label:"Male",
		value:"male"
	},
	{
		label:"Female",
		value:"female"
	},
	{
		label:"Other",
		value:"other"
	}
];

const Countries = [
	{ 
		label: "India", 
		value: "India" 
	}
];

const superadmin_all_Showrooms = [
    {id: "1", value : "Ballygunge",  label: "Ballygunge"},
    {id: "2", value : "Baruipur",  label: "Baruipur"},
    {id: "3", value : "Gariahat",  label: "Gariahat"},
    {id: "4", value : "Howrah",  label: "Howrah"},
    {id: "5", value : "Jadavpur",  label: "Jadavpur"},
    {id: "6", value : "Mumbai",  label: "Mumbai"},
    {id: "7", value : "Ranchi",  label: "Ranchi"},
    {id: "8", value : "Arambagh",  label: "Arambagh"},
    {id: "9", value : "Chandannagar",  label: "Chandannagar"},
    {id: "10", value : "Hatibagan",  label: "Hatibagan"},
    {id: "11", value : "Serampore",  label: "Serampore"},
    {id: "12", value : "Uttarpara",  label: "Uttarpara"},
    {id: "13", value : "Bowbazar",  label: "Bowbazar"},
    {id: "14", value : "Barasat",  label: "Barasat"},
    {id: "15", value : "Habra",  label: "Habra"},
    {id: "16", value : "Koramangala",  label: "Koramangala"},
    {id: "17", value : "Sealdah",  label: "Sealdah"},
    {id: "18", value : "Sodepur",  label: "Sodepur"},
    {id: "19", value : "Agartala",  label: "Agartala"},
    {id: "20", value : "Asansol",  label: "Asansol"},
    {id: "21", value : "Bhubaneswar",  label: "Bhubaneswar"},
    {id: "22", value : "Burdwan",  label: "Burdwan"},
    {id: "23", value : "Balurghat",  label: "Balurghat"},
    {id: "24", value : "Berhampore",  label: "Berhampore"},
    {id: "25", value : "Bankura",  label: "Bankura"},
    {id: "26", value : "Coochbehar",  label: "Coochbehar"},
    {id: "27", value : "Durgapur1",  label: "Durgapur 1"},
    {id: "28", value : "Durgapur2",  label: "Durgapur 2"},
    {id: "29", value : "KrishnaNagar",  label: "Krishna Nagar"},
    {id: "30", value : "Katwa",  label: "Katwa"},
    {id: "31", value : "Midnapore1",  label: "Midnapore 1"},
    {id: "32", value : "Midnapore2",  label: "Midnapore 2"},
    {id: "33", value : "Malda",  label: "Malda"},
    {id: "34", value : "Purulia",  label: "Purulia"},
    {id: "35", value : "Rampurhat",  label: "Rampurhat"},
    {id: "36", value : "Raiganj",  label: "Raiganj"},
    {id: "37", value : "Siliguri",  label: "Siliguri"},
    {id: "38", value : "Siuri",  label: "Siuri"},
    {id: "39", value : "Tamluk",  label: "Tamluk"},
    {id: "40", value : "Pune",  label: "Pune"}
];

const admin_allshowrooms = [
    {id: "1", value : "Ballygunge",  label: "Ballygunge"},
    {id: "2", value : "Baruipur",  label: "Baruipur"},
    {id: "3", value : "Gariahat",  label: "Gariahat"},
    {id: "4", value : "Howrah",  label: "Howrah"},
    {id: "5", value : "Jadavpur",  label: "Jadavpur"},
    {id: "6", value : "Mumbai",  label: "Mumbai"},
    {id: "7", value : "Ranchi",  label: "Ranchi"},
    {id: "8", value : "Arambagh",  label: "Arambagh"},
    {id: "9", value : "Chandannagar",  label: "Chandannagar"},
    {id: "10", value : "Hatibagan",  label: "Hatibagan"},
    {id: "11", value : "Serampore",  label: "Serampore"},
    {id: "12", value : "Uttarpara",  label: "Uttarpara"},
    {id: "13", value : "Agartala",  label: "Agartala"},
    {id: "14", value : "Asansol",  label: "Asansol"},
    {id: "15", value : "Bhubaneswar",  label: "Bhubaneswar"},
    {id: "16", value : "Burdwan",  label: "Burdwan"},
    {id: "17", value : "Balurghat",  label: "Balurghat"},
    {id: "18", value : "Berhampore",  label: "Berhampore"},
    {id: "19", value : "Bankura",  label: "Bankura"},
    {id: "20", value : "Coochbehar",  label: "Coochbehar"},
    {id: "21", value : "Durgapur1",  label: "Durgapur 1"},
    {id: "22", value : "Durgapur2",  label: "Durgapur 2"},
    {id: "23", value : "KrishnaNagar",  label: "Krishna Nagar"},
    {id: "24", value : "Katwa",  label: "Katwa"},
    {id: "25", value : "Midnapore1",  label: "Midnapore 1"},
    {id: "26", value : "Midnapore2",  label: "Midnapore 2"},
    {id: "27", value : "Malda",  label: "Malda"},
    {id: "28", value : "Purulia",  label: "Purulia"},
    {id: "29", value : "Rampurhat",  label: "Rampurhat"},
    {id: "30", value : "Raiganj",  label: "Raiganj"},
    {id: "31", value : "Siliguri",  label: "Siliguri"},
    {id: "32", value : "Siuri",  label: "Siuri"},
    {id: "33", value : "Tamluk",  label: "Tamluk"},
    {id: "34", value : "Pune",  label: "Pune"}
];

const jewellers_showrooms = [
    {id: "1", value : "Ballygunge",  label: "Ballygunge"},
    {id: "2", value : "Baruipur",  label: "Baruipur"},
    {id: "3", value : "Gariahat",  label: "Gariahat"},
    {id: "4", value : "Howrah",  label: "Howrah"},
    {id: "5", value : "Jadavpur",  label: "Jadavpur"},
    {id: "6", value : "Mumbai",  label: "Mumbai"},
    {id: "7", value : "Ranchi",  label: "Ranchi"},
    {id: "8", value : "Pune",  label: "Pune"}
];

const goldlites_showrooms = [
    {id: "1", value : "Arambagh",  label: "Arambagh"},
    {id: "2", value : "Chandannagar",  label: "Chandannagar"},
    {id: "3", value : "Hatibagan",  label: "Hatibagan"},
    {id: "4", value : "Serampore",  label: "Serampore"},
    {id: "5", value : "Uttarpara",  label: "Uttarpara"}

];

const sons_showrooms = [
    {id: "1", value : "Bowbazar",  label: "Bowbazar"},
    {id: "2", value : "Barasat",  label: "Barasat"},
    {id: "3", value : "Habra",  label: "Habra"},
    {id: "4", value : "Koramangala",  label: "Koramangala"},
    {id: "5", value : "Sealdah",  label: "Sealdah"},
    {id: "6", value : "Sodepur",  label: "Sodepur"}
];

const apex_showrooms = [
    {id: "1", value : "Agartala",  label: "Agartala"},
    {id: "2", value : "Asansol",  label: "Asansol"},
    {id: "3", value : "Bhubaneswar",  label: "Bhubaneswar"},
    {id: "4", value : "Burdwan",  label: "Burdwan"},
    {id: "5", value : "Balurghat",  label: "Balurghat"},
    {id: "6", value : "Berhampore",  label: "Berhampore"},
    {id: "7", value : "Bankura",  label: "Bankura"},
    {id: "8", value : "Coochbehar",  label: "Coochbehar"},
    {id: "9", value : "Durgapur1",  label: "Durgapur 1"},
    {id: "10", value : "Durgapur2",  label: "Durgapur 2"},
    {id: "11", value : "KrishnaNagar",  label: "Krishna Nagar"},
    {id: "12", value : "Katwa",  label: "Katwa"},
    {id: "13", value : "Midnapore1",  label: "Midnapore 1"},
    {id: "14", value : "Midnapore2",  label: "Midnapore 2"},
    {id: "15", value : "Malda",  label: "Malda"},
    {id: "16", value : "Purulia",  label: "Purulia"},
    {id: "17", value : "Rampurhat",  label: "Rampurhat"},
    {id: "18", value : "Raiganj",  label: "Raiganj"},
    {id: "19", value : "Siliguri",  label: "Siliguri"},
    {id: "20", value : "Siuri",  label: "Siuri"},
    {id: "21", value : "Tamluk",  label: "Tamluk"}
];

const empty_List = [
    {id: "0", value : "All", label: "All"}
];

const Legalentity_super_admin = [
    {
        id:1,
        isDisabled: false,
        value: 'APEX',
        label: "Apex"
    },
    {
        id:2,
        isDisabled: false,
        value: 'GOLDLITES',
        label: "Goldlites"
    },
    {
        id:3,
        isDisabled: false,
        value: 'JEWELLERS',
        label: "Jewellers"
    },
    {
        id:4,
        isDisabled: false,
        value: 'SONS',
        label: "Sons"
    }
];

const Legalentity_Admin = [
    {
        id:1,
        isDisabled: false,
        value: 'APEX',
        label: "Apex"
    },
    {
        id:2,
        isDisabled: false,
        value: 'GOLDLITES',
        label: "Goldlites"
    },
    {
        id:3,
        isDisabled: false,
        value: 'JEWELLERS',
        label: "Jewellers"
    }
];

const Legalentity_Apex = [
    {
        id:0,
        isDisabled: true,
        value: 'APEX',
        label: "Apex"
    }
];

const Legalentity_Goldlites = [
    {
        id:0,
        isDisabled: true,
        value: 'GOLDLITES',
        label: "Goldlites"
    }
];

const Legalentity_Jwellers = [
    {
        id:0,
        isDisabled: true,
        value: 'JEWELLERS',
        label: "Jewellers"
    }
];

const Legalentity_Sons = [
    {
        id:0,
        isDisabled: true,
        value: 'SONS',
        label: "Sons"
    }
];

const setCookie = (cookiename, usrin) => {
	Cookie.set(cookiename, usrin, {
		expires: 1,
		secure: true,
		sameSite: 'strict',
		path: '/'
	});
}

const getCookie = (cookiename) => {
	return Cookie.get(cookiename);
}

const removeCookie = (cookiename) => {
	Cookie.remove(cookiename);
}

const addZeroBefore = (n) => {
  return (n < 10 ? '0' : '') + n;
}

const isPhoneNumberValid = (no) => {
	const regex = /^[6-9][0-9]{9}$/;
    return regex.test(no);
}

const isEmailValid = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

const extractDateFromMoment = (d) => {
	const day = d.getDate();
	const month = d.getMonth() + 1;
	const year = d.getFullYear();

	const currentDateValue =  year + "-" + addZeroBefore(month) + "-" + addZeroBefore(day);
	return currentDateValue; //in YYYY-MM-DD format
}

const initialAddressData = {
	city : "",
	state: "",
	country: "",
	pincode: "",
    details: ""
}

const initialUserFormData = {
    id: 0,
    spouse_birthday: "",
  	phone_no : "",
    name : "",
    address :  {
		city : "",
		state: "West Bengal",
		country: "India",
		pincode: "",
        details: ""
	},
    email : "",
    pos_id : "",
    gender : "",
    birthday : "",
    anniversary : "",
    onboarding_date: 0,
    details : {
    	spouse_name : "",
        spouse_gender : "",
    	children : [
            {
                child_name : "",
                child_gender : "",
                child_dob : ""
            }
        ]
    },
    other_details : {
        consent: "true"
    },
    legal_entity_id: ""
}

const initialUserFormErrorData = {
    genderError: false,
	nameError : false,
	phoneError : false,
	emailError: false,
    cityError : false,
    countryError : false,
    stateError : false,
    pincodeError : false,
    birthdayError : false,
    anniversaryError: false,
    spouseBirthdayError: false
}

const initialFeedBackDetails = {
    f_ambiencecleanliness : "3",
    f_ambiencedecor : "3",
    f_ambiencelighting : "3",
    f_ambiencemusic : "3",
    f_billingprocedureservice : "3",
    f_goldexchangeservice : "3",
    hearfrom : [],
    isFeedbackFromPOS: "false", 
    otherhearfrom : "",
    preferredmedia : [
        {
            title: "TV",
            data : []
        },
        {
            title: "Radio",
            data : []
        },
        {
            title: "Online DVD",
            data : []
        },
        {
            title: "News Paper",
            data : []
        },
        {
            title: "Movie Theatre",
            data : []
        },
    ],
    preferredmovietheatre : "",
    preferredsocialmedia : [],
    otherpreferredsocialmedia: "",
    f_productrateavailibility : "3",
    f_productratedesign : "3",
    f_productratediamond : "3",
    f_productrategold : "3",
    f_productrateprice : "3",
    salespersonname : "",
    f_salesstaffservice : "3",
    servicecomments : ""
}

const initialfeedbackFormData = {
	datetimestamp : 0,
    customer_name : "",
	customer_phone : "",
	feedback_details: "",
    admin_details: {
        voucher_code: "",
        invoiceno: ""
    },
    legal_entity_id: "",
    pos_id: "",
    other_details: {}
}

const containerVariants = {
  hidden : {
    opacity: 0,
    x: '100vw'
  },
  visible : {
    opacity: 1,
    x: 0
  }
}

const generateYears = () => {
    let currentYear = new Date().getFullYear();
    let earliestYear = 1900;
    var years = [];

    while (currentYear >= earliestYear) {
      let dateOption = {};
      dateOption.label = currentYear;
      dateOption.value = currentYear;
      years.push(dateOption);
      currentYear -= 1;
    }

    return years;
}

const getDate = (date) => {
    if(date === "") return date;
    const dateValArray = date ? date.split('-') : [];
    return dateValArray[0] ? dateValArray[0] : "";
}

const getMonth = (date) => {
    if(date === "") return date;
    const dateValArray = date ? date.split('-') : [];
    return dateValArray[1] ? dateValArray[1] : "";
}

const getYear = (date) => {
    if(date === "") return date;
    const dateValArray = date ? date.split('-') : [];
    return dateValArray[2] ? dateValArray[2] : "";
}

const iSDateValid = (dateStr) => {
  if(dateStr === "") return "";

  const regex = /^\d{2}\/\d{2}\/\d{4}$/;

  const [day, month, year] = dateStr.split('-');

  // 👇️ format Date string as `yyyy-mm-dd`
  const isoFormattedStr = `${year}-${month}-${day}`;
  const date = new Date(isoFormattedStr);

  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(isoFormattedStr);
}

const isJSON = (data) => {
   var ret = true;
   try {
      JSON.parse(data);

   } catch(e) {
      ret = false;
   }
   return ret;
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('-');
}

const States = 
[  
    {  
        "label":"Andhra Pradesh",
        "value": "Andhra Pradesh",
    },
    {  
        "label":"Arunachal Pradesh",
        "value": "Arunachal Pradesh",
    },
    {  
        "label":"Assam",
        "value": "Assam",
    },
    {  
        "label":"Bihar",
        "value": "Bihar",
    },
    {  
        "label":"Chandigarh (UT)",
        "value": "Chandigarh (UT)", 
    },
    {  
        "label":"Chhattisgarh",
        "value": "Chhattisgarh", 
    },
    {  
        "label":"Chandigarh (UT)",
        "value": "Chandigarh (UT)", 
    },
    {  
        "label":"Dadra and Nagar Haveli (UT)",
        "value": "Dadra and Nagar Haveli (UT)", 
    },
    {  
        "label":"Dadra and Nagar Haveli (UT)",
        "value": "Dadra and Nagar Haveli (UT)", 
    },
    {  
        "label":"Delhi (NCT)",
        "value": "Delhi (NCT)", 
    },
    {  
        "label":"Goa",
        "value": "Goa", 
    },
    {  
        "label":"Gujarat",
        "value": "Gujarat", 
    },
    {  
        "label":"Haryana",
        "value": "Haryana", 
    },
    {  
        "label":"Himachal Pradesh",
        "value": "Himachal Pradesh", 
    },
    {  
        "label":"Jammu and Kashmir",
        "value": "Jammu and Kashmir", 
    },
    {  
        "label":"Jharkhand",
        "value": "Jharkhand", 
    },
    {  
        "label":"Karnataka",
        "value": "Karnataka", 
    },
    {  
        "label":"Kerala",
        "value": "Kerala", 
    },
    {  
        "label":"Lakshadweep (UT)",
        "value": "Lakshadweep (UT)", 
    },
    {  
        "label":"Lakshadweep (UT)",
        "value": "Lakshadweep (UT)", 
    },
    {  
        "label":"Madhya Pradesh",
        "value": "Madhya Pradesh", 
    },
    {  
        "label":"Maharashtra",
        "value": "Maharashtra", 
    },
    {  
        "label":"Manipur",
        "value": "Manipur", 
    },
    {  
        "label":"Meghalaya",
        "value": "Meghalaya", 
    },
    {  
        "label":"Mizoram",
        "value": "Mizoram", 
    },
    {  
        "label":"Nagaland",
        "value": "Nagaland", 
    },
    {  
        "label":"Odisha",
        "value": "Odisha", 
    },
    {  
        "label":"Puducherry (UT)",
        "value": "Puducherry (UT)", 
    },
    {  
        "label":"Ranchi",
        "value": "Ranchi", 
    },
    {  
        "label":"Punjab",
        "value": "Punjab", 
    },
    {  
        "label":"Rajasthan",
        "value": "Rajasthan", 
    },
    {  
        "label":"Sikkim",
        "value": "Sikkim", 
    },
    {  
        "label":"Telangana",
        "value": "Telangana", 
    },
    {  
        "label":"Uttarakhand",
        "value": "Uttarakhand", 
    },
    {  
        "label":"Telangana",
        "value": "Telangana", 
    },
    {  
        "label":"Uttar Pradesh",
        "value": "Uttar Pradesh", 
    },
    {  
        "label":"Uttar Pradesh",
        "value": "Uttar Pradesh", 
    },
    {  
        "label":"West Bengal",
        "value": "West Bengal", 
    }
]

const removeAllFields = (obj) => {
    console.log("OBJECT =---", obj)
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            delete obj[key];
        }
    }

    return obj;
}

export {
	addZeroBefore,
	Language_Array, 
    Salutation,
    isJSON,
    formatDate,
    iSDateValid,
    generateYears,
    getDate,
    getMonth,
    getYear,
    Days,
    Months,
	Gender,
	Countries,
	States,
    initialFeedBackDetails,
	initialfeedbackFormData,
	initialUserFormErrorData,
	initialUserFormData,
	initialAddressData,
	extractDateFromMoment,
	isPhoneNumberValid,
	isEmailValid,
	getCookie,
	setCookie,
	removeCookie,
	containerVariants,
	superadmin_all_Showrooms,
    admin_allshowrooms,
    jewellers_showrooms,
    goldlites_showrooms, 
    sons_showrooms,
    apex_showrooms,
    empty_List,
    removeAllFields,
    Legalentity_super_admin,
    Legalentity_Admin,
    Legalentity_Apex,
    Legalentity_Goldlites,
    Legalentity_Jwellers,
    Legalentity_Sons
}