import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import Loading from '../../components/Loading';
import MobileHeader from '../../components/MobileHeader';
import Header from '../../components/Header';
import UserProfile from '../../components/UserProfile';

import VoucherUpdateDialog from './VoucherUpdateDialog';
import FloatTextField from '../../components/FloatTextField';
import {initialfeedbackFormData} from '../../utils/utils';

import Logo from '../../components/Logo';
import './update-voucher.css';

const UpdateVoucher = () => {
  const {t} = useTranslation();
  const titleVal = "Update Voucher - pcccrm";
	const hrefVal = window.location.href;
	const navigate = useNavigate();
  const dispatch = useDispatch();

  const InitialErrors = {
    InvalidVoucherError : false,
    InvalidInvoiceNumberError : false,
  }

  const [feedbackData, setFeedbackData] = useState({});
  const [errors, setErrors] = useState(InitialErrors);
  const [isFormValid, setFormValid] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [isEditMode, setEditMode] = useState(false);
  const [isVoucherFound, setVoucherFound] = useState(false);
  const [isUpdateVoucher, setUpdateVoucher] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [voucherNumber, setVoucherNumber] = useState("");

  const [errorText, setErrorText] = useState("server_error");
  
  const store_legal_Entity_id = localStorage.getItem("Device_Legal_Entity");
  const store_pos_id  = localStorage.getItem("Device_POS_ID");

	const handleSubmit = (e) => {
		e.preventDefault();
   
    const isFormValid = validate(false);
    if(isFormValid) {
      if(!isUpdateVoucher) {
        getfeedbackByAdminDetails();
      } else {
        updateFeedback();
      }
   
      //navigate("/feedback");
    }
	}

  const validate = () => {
    let isFormValid = true;
  
    let isInvalidVoucherError = false;
    let isInvalidInvoiceNumberError = false;
    
    if(invoiceNumber === "") {
      isInvalidInvoiceNumberError = true;
      isFormValid = false;
    }

    if(isUpdateVoucher) {
      if(voucherNumber === "") {
        isInvalidVoucherError = true;
        isFormValid = false;
      }
    }

    setErrors({...errors,
      InvalidVoucherError: isInvalidVoucherError,
      InvalidInvoiceNumberError : isInvalidInvoiceNumberError
    });

    setFormValid(isFormValid);
    return isFormValid;
  }

  const updateFeedback = async () =>  {
    setLoading(true);

    const URL = "https://api.smmc.dev:8908/smmc-pcchandra/updatefeedback";
    feedbackData.admin_details.voucher_code = voucherNumber;

    await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(feedbackData) // body data type must match "Content-Type" header
    })
    .then(res => res.json())
    .then((res) => {
      setLoading(false);
      setError(false);
      setOpen(true);
    })
    .catch((error) => {
      console.log('error', error);
      setLoading(false);
      setError(true);
    });
  }

  const getfeedbackByAdminDetails = async (invoice) => {
    const baseURL = "https://api.smmc.dev:8908/smmc-pcchandra";
    setLoading(true);
    const URL=`${baseURL}/getfeedbackByAdminDetails`;
    
    let obj = {};
    obj.search_id = invoiceNumber;
    obj.pos_id = store_pos_id;

    //console.log(obj)

    return await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(obj), // body data type must match "Content-Type" header
    })
    .then(res => res.json())
    .then((res) => {
      setLoading(false);
      setError(false);

      const feedbackDetails = res.data[0];
  
      if(feedbackDetails && feedbackDetails.feedback_details) {
        setFeedbackData({...feedbackData, ...feedbackDetails, feedback_details: JSON.parse(feedbackDetails.feedback_details),  admin_details: JSON.parse(feedbackDetails.admin_details), other_details: JSON.parse(feedbackDetails.other_details)});
        setUpdateVoucher(true);
      } else {
        setErrorText("No feedback found!");
        setError(true);
      }
    })
    .catch((error) => {
      console.log('error getfeedbackByAdminDetails', error);
      setLoading(false);
      setError(true);
    });
  }

  useEffect(() => {
    if(isUpdateVoucher) {
      const fetchedVoucherCode = feedbackData.admin_details ? feedbackData.admin_details.voucher_code : "";
      setVoucherNumber(fetchedVoucherCode);
      setVoucherFound(fetchedVoucherCode !== "");
    }
  }, [isUpdateVoucher]);

  const handleAdminChange = (e) => {
    setFeedbackData({...feedbackData, admin_details: {...feedbackData.admin_details, [e.target.name] : e.target.value}});
  }

  const onHandleAlert = () => {
   setError(false);
  }

  const onCancel = () => {
    onDialogClose();
  }

  const onDialogClose = () => {
    setOpen(false);
    setUpdateVoucher(false);
    setInvoiceNumber("");
    setFeedbackData({});
    setEditMode(false);
    setVoucherFound(false);
  }

  const updateEditMode = () => {
    setEditMode(true);
    setVoucherFound(false);

  }


  const {
    details,
    loading,
    error
  } = useSelector(({ feedback: { details, loading, error } }) => ({ details, loading, error }));


  useEffect(() => {
    if (!isFormValid) {
      const firstInvalidEle = document.querySelector('[aria-invalid="true"]');
      if (firstInvalidEle) firstInvalidEle.focus();
      setFormValid(true);
    }

  }, [isFormValid]);

  const {
    InvalidVoucherError,
    InvalidInvoiceNumberError
  } = errors;

  // console.log(feedbackData);

	return (
	  <>
    	<Helmet>
      	<title>{titleVal}</title>
      	<meta name="description" content="Login meta"></meta>
      	<link rel="canonical" href={hrefVal} />
    	</Helmet>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-0">
            <Logo />
          </div>

          <div className="align-items-center col-lg-8 col-md-12">
            <div className="mobile_header">
              <MobileHeader label = "Feedback" />
            </div>

              <div>
                <Header isShow={true} />
              </div>
              <div className="update-voucher">
                <form className="px-lg-5 px-1 pt-2" onSubmit={e=>handleSubmit(e)} autoComplete="off" data-group-name="digits" data-autosubmit="false" noValidate>
                  <div className="row">
                    <div className="col-lg-12 w-100 mt-3">
                      <div>
                        <h1 className="heading fw-bold py-3">{t("Add or update voucher code")}</h1>
                      </div> 

                  	  <div className="py-2">
                    	  <FloatTextField isDisabled={isUpdateVoucher} isError={InvalidInvoiceNumberError} label={t("invoice_no")} value={invoiceNumber} name="invoiceno" isRequired={true} onInputChange={(e) => setInvoiceNumber(e.target.value)} />
                  	    {InvalidInvoiceNumberError && <div className="text-danger fw-bold error_text">! {t("send_link_invoice_number_error_message")}</div>}
                      </div>

                      {isUpdateVoucher &&
                        <div className="py-2">
                          <FloatTextField isError={InvalidVoucherError} label={t("voucher_code")} value={voucherNumber} name="voucher_code" isRequired={false} isDisabled={isVoucherFound} onInputChange={(e) => setVoucherNumber(e.target.value)} />
                          {InvalidVoucherError && <div className="text-danger fw-bold error_text">! {t("send_link_voucher_code_error_message")}</div>}
                        </div>
                      }
                    </div>
                  </div>

                  {isVoucherFound && <div className="my-3"><i class='bx bx-message-rounded-error'></i> A Voucher code is already exist. To update it press the 'Edit Voucher' button.</div>}

                  <div className="d-flex justify-content-lg-end justify-content-center px-2 py-3">
                    <button disabled={!isUpdateVoucher} className="btn btn-outline-danger px-5 me-3" type="button" onClick={onCancel}>{t("Cancel")}</button>
                    {isVoucherFound && !isEditMode && <button className="btn btn-danger px-5" onClick={updateEditMode}>Edit Voucher</button>}
                  	{!isVoucherFound && <button disabled={(isUpdateVoucher && (voucherNumber === "" || invoiceNumber === "")) || (!isUpdateVoucher && invoiceNumber === "")} className="btn btn-danger px-5" type="submit">{isUpdateVoucher ? t("Update Feedback") : t("Get Feedback")}</button>}
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
      {(loading || isLoading) && <Loading />}
      {isOpen && <VoucherUpdateDialog isOpen={isOpen} onHandleCloseDialog={onDialogClose} invoice={invoiceNumber} voucher={voucherNumber} />}
      {(error || isError) && <CustomizedSnackbars isShow = {error || isError} text={t(errorText)} showAlert={(isHide) => onHandleAlert(isHide)} />}
    </>
	)
}

export default UpdateVoucher;