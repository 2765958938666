import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../../components/Logo';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import FullDialog from '../../components/FullDialog';
import Loading from '../../components/Loading';
import Switch from '@mui/material/Switch';
import FloatSelect from '../../components/FloatSelect';
import FloatTextField from '../../components/FloatTextField';

import {initialUserFormData, initialfeedbackFormData, initialFeedBackDetails, isPhoneNumberValid, isJSON } from '../../utils/utils';
import logo from '../../assets/logo.png';
import logo_2 from '../../assets/security-user.png';
import {saveCustomerData} from '../../Actions/customer.actions';
import {saveFeedbackData} from '../../Actions/feedback.actions';
import './login.css';
import {PCC_crm_version} from '../../Actions/Constants';

const CustomerLogin = () => {
  const titleVal = "Customer Login - pcccrm";
  const navigate = useNavigate();
  const {t} = useTranslation();
  const hrefVal = window.location.href;
  const search = useLocation().search;
  const dispatch = useDispatch();

  const phone_val = new URLSearchParams(search).get('phone');
  const pos_val = new URLSearchParams(search).get('pos');
  const invoice_val = new URLSearchParams(search).get('invoice');

  const [pos, setPOS] = useState(pos_val);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isInvalidPhone, setInvalidPhone] = useState(false);
  const [isFormSubmit, setFormSubmit] = useState(false);
  const [isInvalidOTP, setInvalidOTP] = useState(false);
  const [customerData, setCustomerData] = useState(initialUserFormData);
  const [formData, setFormData] = useState(initialfeedbackFormData);
  const saveCustomerDataAction = saveCustomerData;
  const saveFeedbackDataAction = saveFeedbackData;
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isFeedBackExist, setFeedbackExist] = useState(false);
  
  const getfeedbackByAdminDetails = async (invoice) => {
    const baseURL = "https://api.smmc.dev:8908/smmc-pcchandra";
    setLoading(true);
    const URL=`${baseURL}/getfeedbackByAdminDetails`;

    let obj = {};
    obj.search_id = invoice;
    obj.pos_id = pos;

    return await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(obj), // body data type must match "Content-Type" header
    })
    .then(res => res.json())
    .then((res) => {
      localStorage.setItem("isFeedbackFromPOS", "false");
      localStorage.setItem("Device_POS_ID", pos);
      localStorage.setItem("PCC_crm_version", PCC_crm_version);
      setLoading(false);
      setError(false);
      const feedbackDetails = res.data[0];
  
      if(feedbackDetails && feedbackDetails.feedback_details) { 
        setFormData({...formData, ...feedbackDetails, feedback_details: JSON.parse(feedbackDetails.feedback_details),  admin_details: JSON.parse(feedbackDetails.admin_details), other_details: JSON.parse(feedbackDetails.other_details)});
      }
    })
    .catch((error) => {
      console.log('error getfeedbackByAdminDetails', error);
      setLoading(false);
      setError(true);
    });
  }

  const onHandleAlert = () => {
    //setError(false);
  }

  const handlePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmit(true);

    const isNumberValid = isPhoneNumberValid(phoneNumber);
    
    if(isNumberValid) {
      setInvalidPhone(false);
      customerData.phone_no = phoneNumber;
      customerData.pos_id = pos;

      dispatch(saveCustomerDataAction(customerData));
      if(phoneNumber !== "" && pos !== "" && invoice_val !== "") {
        getfeedbackByAdminDetails(invoice_val);
      }
    } else {
      setFormSubmit(false);
      setInvalidPhone(true);
      const phone_number = document.getElementById("phone_number");
      phone_number.focus();
    }
  }

  useEffect(() => {
    setPhoneNumber(phone_val);
  }, [phone_val]);

  useEffect(() => {
    setPOS(pos_val);
  }, [pos_val]);

  useEffect(() => {
    if(isFormSubmit) {
      if(formData && formData.feedback_details && !formData.feedback_details.f_ambiencecleanliness) {
        dispatch(saveFeedbackDataAction(formData));
        setFormSubmit(false);
        navigate("/feedback");
      } else {
        setFeedbackExist(true)
      }
    }
  }, [formData]);

  const onDialogClose = () => {
    
    //Navigate to login page
    setFeedbackExist(false)
    window.location.href = "https://pcchandraindia.com/";
  }

  return (
    <>
      <Helmet>
        <title>{titleVal}</title>
        <meta name="description" content="Login meta"></meta>
        <link rel="canonical" href={hrefVal} />
      </Helmet>

      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-0">
              <Logo />
            </div>

            <div className="container-view d-flex justify-content-center login_container align-items-center col-lg-8 col-md-12 p-4 pt-0 ">
              
              <div className="w-100">
                <div className="d-flex justify-content-center">
                  <img src={logo_2} style={{width : "25%"}} />
                </div>
                <form className="px-lg-3 py-5" onSubmit={(e) => handleSubmit(e)} autoComplete="off" data-group-name="digits" data-autosubmit="false" noValidate>
                  <div className="row">
                    <div className="col-lg-8 w-100 mt-3 mb-5">
                      <div className="p-0">
                        <div className="fw-bold py-3">{t("customer_phone_number")} *</div>
                      </div>
                      <FloatTextField isDisabled = {true} name="phone_number" value={phoneNumber} id="phone_number" label={t("send_link_phone_number_placeholder")} onInputChange = {(e) => handlePhoneNumber(e)} />
                      {isInvalidPhone && <div className="text-danger fw-bold">! {t("phone_number_error")}</div>}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center pb-3">
                    <div className="terms_text OpenSans">
                      {t("login_agree_terms_condition")}
                    </div>
                    <div>
                      <Switch defaultChecked />
                    </div>
                  </div>

                  <div className="d-flex justify-content-end pt-5">
                    <button type="submit" className="btn btn-danger ms-5 px-5">{t("confirm")}</button>
                  </div>
                </form>
              </div>
            </div>
            {isError  && <CustomizedSnackbars isShow={isError} text="No customer registered with this phone number" showAlert={(isHide) => onHandleAlert(isHide)} />}
            {isFeedBackExist && <FullDialog />}
            {isFeedBackExist && <FullDialog isExist = {isFeedBackExist} isOpen={isFeedBackExist} onHandleCloseDialog={onDialogClose} />}
      
            {isLoading && <Loading/> }
          </div>
        </div>
      </div>
    </>
  )
};

export default CustomerLogin;
