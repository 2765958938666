import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Loading from '../components/Loading';
import Header from '../components/Header';
import UserInfo from './UserInfo';
import Ambience from './Ambience';
import ProductRating from './ProductRating';
import Service from './Service';
import Media from './Media';
import PreferMedia from './PreferMedia';
import ThankYou from './ThankYou';
import StepperControl from '../components/Stepper';

import { isJSON, initialfeedbackFormData, initialAddressData, initialUserFormData, initialFeedBackDetails, getCookie } from '../utils/utils';
import {saveCustomerData, getcustomerByPhoneAndPOS} from '../Actions/customer.actions';
import Logo from '../components/Logo';
import MobileHeader from '../components/MobileHeader';

const Landing = (props) => {
  const titleVal = "Feedback - pcccrm";
  const dispatch = useDispatch();
  const hrefVal = window.location.href;
	const { onPageIndex } = props;

  const navigate = useNavigate();
	const [currentIndex, setCurrentIndex] = useState(1);
  const [currentUserIndex, setCurrentUserIndex] = useState(1);
  const [userData, setUserData] = useState(initialUserFormData);
	const [formData, setFormData] = useState(initialfeedbackFormData);
  const [label = "", setLabel] = useState("");
 
  const [stepperText, SetStepperText] = useState("About You");
  const getcustomerByPhoneAndPOSAction = getcustomerByPhoneAndPOS;

  useEffect(() => {
    getLabelText();
    updateStepperText();
  }, [currentIndex]);

  const {
    details,
    loading,
    error
  } = useSelector(({ customer: { details, loading, error } }) => ({ details, loading, error }));

  const {
    details: feedbackDetails,
    loading: feedbackLoading,
    error: feedbackError
  } = useSelector(({ feedback: { details, loading, error } }) => ({ details, loading, error }));

  useEffect(() => {
    if(feedbackDetails) {
      setFormData({...formData, ...feedbackDetails, feedback_details: {...initialFeedBackDetails, salespersonname: feedbackDetails.feedback_details.salespersonname}});
    }
  }, [feedbackDetails]);

  const updateStepperText = () => {
    var textVal = "";
    switch(currentIndex) {
      case 1 :
        textVal = "About You";
        break;
      case 2:
        textVal = "Experience at showroom";
        break;
      case 3:
        textVal = "Experience with products";
        break;
      case 4:
        textVal = "Experience with services";
        break;
      case 5:
      case 6:
        textVal = "Touch points";
        break;
      case 7 : 
        textVal = "Purchase Details";
        break;
      default : break;
    }
    SetStepperText(textVal);
  }

  const updateStepperDetails = (indexVal) => {
    setCurrentUserIndex(indexVal);
  }

  useEffect(() => {
    if(details) {
      dispatch(getcustomerByPhoneAndPOSAction(details.phone_no, details.pos_id)); 
    }
  }, []);

  useEffect(() => {
    try {
      if(details !== undefined && details.onboarding_date && details.onboarding_date !== 0)  {
        setUserData({...details, address: JSON.parse(details.address),  details: JSON.parse(details.details), other_details: JSON.parse(details.other_details)});
      }
    } catch {
      navigate("/logout");
    }
  }, [details]);

  const getLabelText = () => {
    switch(currentIndex) {
      case 1 : 
        setLabel("Feedback");
        break;
      case 2 : 
        setLabel("Ambience");
        break;
      case 3 : 
        setLabel("Products");
        break;
      case 4 : 
        setLabel("Service");
        break;
      case 5 : 
        setLabel("Touch Points");
        break;
      case 6 : 
        setLabel("Touch Points");
        break;
      case 7 : 
        setLabel("Purchase Details");
        break;
    }
  }

  console.log(userData);
  console.log(formData);

	return (
    <>
      <Helmet>
        <title>{titleVal}</title>
        <meta name="description" content="Login meta"></meta>
        <link rel="canonical" href={hrefVal} />
      </Helmet>
      <div className="container-fluid body-container">
        <div className="row">
          <div className="col-lg-4 col-md-0 justify-content-center">
            <Logo label = {label} />
          </div>
          <div className="mobile_header">
            <MobileHeader label = {label} />
          </div>
          <div className="col-lg-8 col-md-12">
            <Header currentIndex = {currentIndex} currentUserIndex = {currentUserIndex} stepperText = {stepperText} />
            <div className="container-view">
              {currentIndex === 1 && <UserInfo setLabel={setLabel} userData={userData} setUserData={setUserData} onUserStepperChange = {(inxVal) => updateStepperDetails(inxVal)}  onPageIndex = {(index) => setCurrentIndex(index)} />}
              {currentIndex === 2 && <Ambience formData={formData} setFormData={setFormData} onPageIndex = {(index) => setCurrentIndex(index)} />}
              {currentIndex === 3 && <ProductRating formData={formData} setFormData={setFormData} onPageIndex = {(index) => setCurrentIndex(index)}/>}
              {currentIndex === 4 && <Service formData={formData} setFormData={setFormData} onPageIndex = {(index) => setCurrentIndex(index)}  />}
              {currentIndex === 5 && <Media formData={formData} setFormData={setFormData} onPageIndex = {(index) => setCurrentIndex(index)} />}
              {currentIndex === 6 && <PreferMedia formData={formData} setFormData={setFormData} onPageIndex = {(index) => setCurrentIndex(index)} />}
              {currentIndex === 7 && <ThankYou userData={userData} formData={formData} setFormData={setFormData} onPageIndex = {(index) => setCurrentIndex(index)} />}
            </div>
          </div>
        </div>
        {loading && <Loading />}
      </div>
    </>
	)
}

export default Landing;