import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import Loading from '../components/Loading';
import FullDialog from '../components/FullDialog';
import CheckBox from '../components/CheckBox';
import CustomizedSnackbars from '../components/CustomizedSnackbars';
import FooterControls from '../components/FooterControls';
import {PreferMediaData, Ranchi_PreferMediaData , PreferMovieData} from '../assets/Data.js';
import {containerVariants} from '../utils/utils';

const PreferMedia = (props) => {
	const {t} = useTranslation();
	const {formData={}, setFormData, onPageIndex } = props;
	const navigate = useNavigate();
	const [isFormValid, setFormValid] = useState(true);
	const [isError, setError] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const [isLoading, setLoading] = useState(false);
	
	const data = formData.feedback_details.preferredmovietheatre;
	const [PreferMediaList, setPreferMediaList] = useState(formData.feedback_details.preferredmedia || []);

  	const handleChange = (e, index) => {
	    const { value } = e.target;
	    let text = "";

	    var list = formData.feedback_details.preferredmedia[index].data;
	    if(!list.includes(value)) {
	    	list.push(value)
	    } else {
	    	const newList = list.filter((item, index) => list[index]  !== value);
	    	list = newList;
	    }

	    PreferMediaList[index].data = list;
	    setFormData({...formData, feedback_details: {...formData.feedback_details, ["preferredmedia"] : PreferMediaList}});
  	}

	const validate = (e) => {
		let isFormValid = true;

		if(preferredmedia === "") {
	  		isFormValid = false;
		}

		setFormValid(isFormValid);
		return isFormValid;
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		const isFormValid = validate();
		if(isFormValid) {
			onPageIndex(7);
		}
	}
	
	const handleBack = () => {
		onPageIndex(5);
	}

	useEffect(() => {
		const headerEle = document.querySelector("header");
		if (headerEle) {
	  		headerEle.scrollIntoView({top: 0,behavior: "smooth"});
		}
	}, []);

	const {
		preferredmedia,
		preferredmovietheatre
	} = formData.feedback_details;

	const stored_POS = localStorage.getItem("Device_POS_ID");

	const MediaList = (stored_POS === "Ranchi") ? Ranchi_PreferMediaData : PreferMediaData;

	return (
		<div className="container-fluid">
			<form className="px-lg-3 px-sm-1" onSubmit={(e) => handleSubmit(e)} noValidate>
				<h1 className="py-3 heading">{t(PreferMediaData.title)}</h1>
				<div className=" movie-wrapper">
				    <div>
					    {MediaList.fields.map((item, index) => {
						    return (
						    <div>
							    <div className="d-flex">
							    	<div className="w-25">
				    					<div>{`0${index + 1}`}</div>
				    					<div className="Roboto font18">{t(item.title)}</div>
						    		</div>
						    		<div className="wrapper media_wrapper w-75">
						    		{item.list.map((listItem, itemIndex) => {
						    			const isChecked = preferredmedia[index].data.includes(listItem.value);
							    		return (
							    			<div>
							      				<CheckBox
							      				 	index={index}
							      				 	itemIndex = {itemIndex}
							      					isChecked={isChecked} 
							      					item={listItem} 
							      					name="preferredsocialmedia"
							      					onInputChange = {(e) => handleChange(e, index)} 
							      				/>
							    			</div>
							    		)
							    	})
							        }
							    	</div>
							    </div>
						    <hr />
						    </div>
						    )})
					    }
				    </div>
				</div>

				<FooterControls onBackBtnClick={handleBack} />
				
			</form>
    	</div>        
	)
}

export default PreferMedia;