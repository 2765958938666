import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import {Language_Array} from '../utils/utils';
import { getCookie } from '../utils/utils';
import LanguageDropdown from './LanguageDropdown';
import UserProfile from './UserProfile';
import StepperControl from '../components/Stepper';
import './header.css';


const Header = (props) => {
	const [lang, setLang] = useState('en');
	const navigate = useNavigate();
	const [loggedUser, setloggedUser] = useState({});
	const isFeedbackFromPOS = localStorage.getItem("isFeedbackFromPOS");

	const { isShow = false, isLogin = false, currentIndex = 0, currentUserIndex = 1,  stepperText = "" } = props;
	
	const {
    	details : loggedAdminDetails
  	} = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));
	
	return (
		<header>
			<div className="container-fluid">
				<div className='row '>
					<div className="col-lg-7 col-md-7 col-12">
						{!isShow && <div className="stepper_view pe-1">
							<StepperControl currentStep={currentIndex} subActiveStep ={currentUserIndex} text={stepperText} />
						</div>}
					</div>
				
					<div className="col-lg-5 col-md-5 col-12 language_home_container">
						<div className="d-flex align-items-center language_container">
							<div className="">
								{(currentUserIndex === 1 && !isShow) && <div className="ps-1 pe-3">
								<LanguageDropdown />
								</div>}
							</div>

							<div>
								{isShow && loggedAdminDetails && loggedAdminDetails.emp_name && 
									<UserProfile name={loggedAdminDetails && loggedAdminDetails.emp_name || "Guest"} email={loggedAdminDetails && loggedAdminDetails.emp_email || ""} />
								}

								{!isShow && loggedAdminDetails && loggedAdminDetails.emp_name && isFeedbackFromPOS === "true" && 
									<Link to="/" className="p-1 px-2 user-pic">
										<i className="text-black fa-sharp fa-solid fa-house "></i>
									</Link>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header