import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {AmbienceData} from '../assets/Data.js';
import CustomizedSnackbars from '../components/CustomizedSnackbars';
import FooterControls from '../components/FooterControls';
import Ambience1 from './Ambience/Ambience1';
import Ambience2 from './Ambience/Ambience2';
import RadioView from '../components/RadioView';
import {containerVariants} from '../utils/utils';

const Ambience = (props) => {
  const {t} = useTranslation();
  const { formData={}, setFormData , onPageIndex} = props;
  const [isFormValid, setFormValid] = useState(true);
  const [isError, setError] = useState(false);
 
  const handleChange = (e) => {
    setFormData({...formData, feedback_details: {...formData.feedback_details, [e.target.name] : e.target.value}});
  }

  const onHandleAlert = () => {
    setError(false);
  }

  const validate = (e) => {
    let isFormValid = true;

    if(f_ambiencecleanliness === "") {
      isFormValid = false;
    }
    if(f_ambiencedecor === "") {
      isFormValid = false;
    }

    if(f_ambiencelighting === "") {
      isFormValid = false;
    }
    if(f_ambiencemusic === "") {
      isFormValid = false;
    }
    
    setFormValid(isFormValid);
    return isFormValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validate();

    if(isFormValid) {
      setError(false);
      onPageIndex(3);
    }
  }

  const handleBack = () => {
    onPageIndex(1);
  }

  useEffect(() => {
    if (!isFormValid) {
      setFormValid(true);
      setError(true);
    }
  }, [isFormValid]);

  useEffect(() => {
    const headerEle = document.querySelector("header");
    if (headerEle) {
      headerEle.scrollIntoView({top: 0,behavior: "smooth"});
    }
  }, []);

  const {
	 f_ambiencecleanliness = "",
	 f_ambiencedecor = "",
	 f_ambiencelighting = "",
	 f_ambiencemusic = ""
  } = formData.feedback_details;

  return (
    <div>
    
    <form className="px-lg-3" onSubmit={(e) => handleSubmit(e)} noValidate>
        <h1 className="heading py-3">{t(AmbienceData.title)}</h1>
        <div>
          <Ambience1 formData={formData} setFormData={setFormData} />
        </div>
        <FooterControls onBackBtnClick={handleBack} />
    </form>
    {isError && <CustomizedSnackbars isShow={isError} text={t("Choose_one")} showAlert={(isHide) => onHandleAlert(isHide)} />}
    </div>
  )
}

export default Ambience;