import {
  GET_CUSTOMER_START,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  SET_CUSTOMER_DATA,
  GET_CUSTOMER_DATA,
  REMOVE_CUSTOMER_DATA
} from '../../Actions/Constants';
import {removeAllFields} from '../../utils/utils';
   
const defaultState = {
  details: {},
  error: null,
  loading: false
};
 
export default function customerReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_CUSTOMER_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        details: action.payload,
        error: null,
        loading: false
      };
    case GET_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case SET_CUSTOMER_DATA:
      return {
        ...state,
        loading: false,
        error: null,
        details: action.payload,
      };
      case GET_CUSTOMER_DATA:
        return {
          ...state,
          loading: false,
          error: null
        };
      case REMOVE_CUSTOMER_DATA:
        return {
          ...state,
          loading: false,
          details: removeAllFields(state?.details),
          error: null
        };
    default:
      return state;
  }
}