import {
  ADD_FEEDBACK_START,
  ADD_FEEDBACK_SUCCESS,
  ADD_FEEDBACK_FAILURE,
  GET_FEEDBACK_START,
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_FAILURE,
  SET_FEEDBACK_DATA,
  REMOVE_FEEDBACK_DATA,
  baseURL
} from '../../Actions/Constants';

import {removeAllFields} from '../../utils/utils';
   
const defaultState = {
  details: {},  //[]
  error: null,
  loading: false
}; 
 
export default function feedbackReducer(state = defaultState, action) {
  switch (action.type) {

    case ADD_FEEDBACK_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ADD_FEEDBACK_SUCCESS:
      return {
        ...state,
        details: action.payload,
        loading: false,
        error: null
      };
    case ADD_FEEDBACK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case GET_FEEDBACK_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        details: action.payload,
        error: null,
        loading: false
      };
    case GET_FEEDBACK_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case SET_FEEDBACK_DATA:
      return {
        ...state,
        loading: false,
        details: action.payload,
      };
    case REMOVE_FEEDBACK_DATA :
    console.log("details", state?.details)
      return {
        ...state,
        loading: false,
        details: removeAllFields(state?.details),
        error: null
      }
    default:
      return state;
  }
}