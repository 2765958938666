import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import translationEN from './locales/en/translationEN.json';
import translationBengali from './locales/bn/translationBengali.json';
import translationHindi from './locales/hi/translationHindi.json';

i18n
 .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {translation: translationEN},
      bn: {translation: translationBengali},
      hi: {translation: translationHindi}
    },
    lng: 'en',
    supportedLngs: ["en", "bn", "hi"],
    fallbackLng: 'en',
    keySeparator: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
  });

export default i18n;