import {
  SET_CUSTOMER_DATA,
  GET_CUSTOMER_DATA,
  REMOVE_CUSTOMER_DATA,
  GET_CUSTOMER_START,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  baseURL
} from './Constants';


export const getcustomerByPhoneAndPOS = (phone, pos_id) => async (dispatch) => {
  dispatch({
    type: GET_CUSTOMER_START,
    error: null
  });

  let obj = {};
  obj.phone_no = phone;
  obj.pos_id = pos_id;
  
  const URL=`${baseURL}/getcustomerByPhoneAndPOS`;
 
  return await fetch(URL, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify(obj), // body data type must match "Content-Type" header
  })
  .then(res => res.json())
  .then((res) => {
    dispatch({
      type: GET_CUSTOMER_SUCCESS,
      payload: res.data[0],
      error: null
    });
  })
  .catch((error) => {
    console.log('error getEmployeeByPhone', error);
    dispatch({
      type: GET_CUSTOMER_FAILURE,
      error: error,
    });
  });
}

export const saveCustomerData = (user) => (dispatch) => {
  dispatch({
    type: SET_CUSTOMER_DATA,
    payload: user,
    error: null
  });
};

export const getCustomerData = (user) => (dispatch) => {
  dispatch({
    type: GET_CUSTOMER_DATA,
    payload: user,
    error: null
  });
};

export const removeCustomerData = () => (dispatch) => {
  dispatch({
    type: REMOVE_CUSTOMER_DATA,
    payload: {},
    error: null
  });
};