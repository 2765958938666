import React from 'react';
import { Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {PCC_crm_version} from './Actions/Constants';

const Protected = ({children}) => {
  const {
    details,
    loading,
    error
  } = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));


  const app_version = localStorage.getItem("PCC_crm_version");
  const isSameVersion = (app_version && app_version === PCC_crm_version);

  const isUserPresent = (details && details.emp_phone_no && details.emp_phone_no !== "" && isSameVersion) ? true : false;
  
  return isUserPresent ? children : <Navigate to="/login" replace />;
};

export default Protected;