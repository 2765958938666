import React, {useState, useEffect} from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { StyledEngineProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {addZeroBefore} from '../utils/utils';
import './datePicker.css';

export default function BasicDatePicker(props) {

  const { label = "Birthday", name = "birthday", value = "" , isError = false, isRequired=false, onInputChange } = props;
  const [val, setValue] = useState(value === "" ? null : dayjs(value));
  
  useEffect(() => {
    if (val !== "")  {
      onInputChange(val, name);
    }
  }, [val]);

  useState(() => {
    setValue(value);
  }, [value])

  const currentVal = (value === "") ? null : dayjs(value);
  return (

    <div className="DatePicker">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker label={label}
            defaultValue={currentVal}
            format={"MM-DD-YYYY"}
            disableFuture={true}
            value={currentVal}
            name={name}
            onChange={(newValue) => {
              const date = (newValue.$d);
              const day = addZeroBefore(date.getDate());
              const month = addZeroBefore(date.getMonth() + 1);
              const year = date.getFullYear();
              let dateMDY = `${month}-${day}-${year}`;
              
              setValue(dateMDY);
            }} 
          
         />
      </DemoContainer>
    </LocalizationProvider>
    </div>
  );
}
