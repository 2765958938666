import React, {useState, useEffect} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import Customer_Experience_card from "../assets/Customer_Experience_card.png";

export default function FullDialog(props) {
  const {isExist = false, isOpen, children, onHandleCloseDialog} = props;
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  const {t} = useTranslation();
  const isFeedbackFromPOS = localStorage.getItem("isFeedbackFromPOS");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
    onHandleCloseDialog(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);


  return (
    <React.Fragment>
      <Dialog 
        onClose={handleClose} 
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-label=""
        onBackdropClick="false"
        >
        <div className="px-lg-5 px-3 py-4">

          <div className="d-flex flex-column justify-content-center text-center dialog">
          <img src={Customer_Experience_card} className="thank_you_image" />
            <div className="libreBodoni thank_you">{t("thank_you")}</div>
            {!isExist && <div className="Roboto font36">{t("submit_text_1")}</div>}
            {isExist && <div className="Roboto font36">{t("submit_text_5")}</div>}
            {isFeedbackFromPOS == "true" && <div className="OpenSans font24 py-2">{t("submit_text_2")}</div>}
          </div>

          {isFeedbackFromPOS == "true" && <div className="my-3 m-auto d-flex justify-content-center">
            <button className="btn btn-danger px-3" onClick={handleClose}>{t('submit_text_4')}</button>
          </div>}

          {isFeedbackFromPOS == "false" && <div className="my-3 m-auto d-flex justify-content-center">
            <a href="https://pcchandraindia.com/" className="btn btn-danger px-3">{t('submit_text_3')}</a>
          </div>}
        </div>
      </Dialog>
    </React.Fragment>
  );
}
