import { useTranslation } from 'react-i18next';

const CardCheckBox = (props) => {
	const {isChecked = false, name = "", onInputChange = (e) => {}} = props;
    const {img = "", title = "", icon = "Star_jalsa", value = ""} = props.item;
    const {t} = useTranslation();
	
	const onHandleChange = (e) => {
		onInputChange(e);
	}

	return (
		<>
		<input checked={isChecked} value={value} id={title} name={name} className="radio" type="checkbox" className="a11y" onChange={e => onHandleChange(e)} />
      	<label for={title} className="m-2 py-2 shadow-sm border Roboto card-radio rounded flex-row d-flex align-items-center justify-content-center">   
        	<img src={img} className="p-2" style={{width: '40px'}}/>
        	<div>{t(title)}</div> 
        	{isChecked && <i class="fa-regular fa-circle-check px-2 tick_icon"></i>}
      	</label>
        </>
	)
}

export default CardCheckBox;