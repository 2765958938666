import { combineReducers } from 'redux';
import adminReducer from './Admin/admin.reducer';
import customerReducer from './Customer/customer.reducer';
import feedbackReducer from './Feedback/feedback.reducer';

const appReducer = combineReducers({
  loggedAdmin: adminReducer,
  customer: customerReducer,
  feedback: feedbackReducer
});

const rootReducer = (state, action) => {
	if (action.type  === "USER_LOGGED_OUT") {
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;