import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import FloatSelect from '../../components/FloatSelect';
import MobileHeader from '../../components/MobileHeader';
import logo_2 from '../../assets/security-user.png';
import Logo from '../../components/Logo';

import { 
  Legalentity_super_admin,
  Legalentity_Admin, 
  Legalentity_Apex,
  Legalentity_Goldlites,
  Legalentity_Jwellers,
  Legalentity_Sons,
  superadmin_all_Showrooms,
  admin_allshowrooms,
  jewellers_showrooms,
  goldlites_showrooms, 
  sons_showrooms,
  apex_showrooms,
} from '../../utils/utils';

const AdminSetup = () => {
  const {t} = useTranslation();
  const titleVal = "Configure device - pcccrm";
	const hrefVal = window.location.href;
	const navigate = useNavigate();

  const stored_LE = localStorage.getItem("Device_Legal_Entity");
  const stored_POS = localStorage.getItem("Device_POS_ID");
  
  const [legal_entity_id, setLE] = useState(stored_LE || "");
  const [pos_id, setPOS] = useState(stored_POS || "");
  const [POS_LIST, setPOSLIST] = useState(superadmin_all_Showrooms);
  const [isFormValid, setFormValid] = useState(true);
 
  const InitialErrors = {
    InvalidPOSError: false,
    InvalidLEError : false
  };

  const [errors, setErrors] = useState(InitialErrors);

	const handleSubmit = (e) => {
		e.preventDefault();
    const isFormValid = validate();

    if(isFormValid) {
      navigate("/");
    }
	}
  const validate = () => {
    let isFormValid = true;
    let isInvalidPOSIDError = false;
    let isInvalidLEError = false;

    if(legal_entity_id === "" || legal_entity_id === "All" || legal_entity_id === "all") {
      isInvalidLEError = true;
      isFormValid = false;
    }

    if(pos_id === "" || pos_id === "All" || pos_id === "all") {
      isInvalidPOSIDError = true;
      isFormValid = false;
    }

    setErrors({...errors,
      InvalidPOSError : isInvalidPOSIDError,
      InvalidLEError : isInvalidLEError
    });

    if(isFormValid) {
      localStorage.setItem("Device_Legal_Entity", legal_entity_id);
      localStorage.setItem("Device_POS_ID", pos_id);
    }

    setFormValid(isFormValid);
    return isFormValid;
  }

  const {
    InvalidPOSError,
    InvalidLEError
  } = errors;

  const getLegalEntity = () => {
    let entity_List = Legalentity_super_admin;
    let  room_List = admin_allshowrooms;

    switch(legal_entity_id) {
      case "APEX" : 
        entity_List = Legalentity_Apex;
        room_List = apex_showrooms;
      break;
      case "GOLDLITES" : 
        entity_List = Legalentity_Goldlites;
        room_List = goldlites_showrooms;
      break;
      case "JEWELLERS" : 
        entity_List = Legalentity_Jwellers;
        room_List = jewellers_showrooms;
      break;
      case "SONS" : 
        entity_List = Legalentity_Sons;
        room_List = sons_showrooms;
      break;
      default : break;
    };

    if(POS_LIST !== room_List) {
      //setPOS("");
    }
    
    setPOSLIST(room_List);
  }

  useEffect(() => {
    getLegalEntity();
  }, [legal_entity_id]);

	return (
	  <>
    	<Helmet>
      	<title>{titleVal}</title>
      	<meta name="description" content="Login meta"></meta>
      	<link rel="canonical" href={hrefVal} />
    	</Helmet>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-0">
            <Logo />
          </div>

          <div className="mobile_header">
            <MobileHeader label = "Feedback" />
          </div>
         
          <div className=" align-items-center col-lg-8 col-md-12">
              <form className="px-lg-3" onSubmit={e=>handleSubmit(e)} autoComplete="off" data-group-name="digits" data-autosubmit="false" noValidate>
                <div className="row">
                  <div className="col-lg-12 w-100 mt-3">
                    
                    <div className="d-flex justify-content-center login_form_container m-auto h-100 flex-column">
                      <div className="d-flex justify-content-center pb-5">
                        <img src={logo_2} style={{width : "25%"}} alt="" />
                      </div>

                      <div>
                        <h1 className="heading fw-bold py-3">{t("Configure device")}</h1>
                      </div>

                      <div className="Roboto font18">{t('This is a one time activity. This configures the device for the selected POS')}.</div>
                    
                      <div className="w-100">
                        <div className="py-3">
                          <FloatSelect list={Legalentity_super_admin} isError={false} label="Legal Entity" value={legal_entity_id} name="legal_entity_id" isRequired={true} onInputChange={(e) => setLE(e.target.value)} />
                          {InvalidLEError && <div className="text-danger fw-bold error_text">! {t("send_link_LE_error_message")}</div>}
                        </div>
                        <div className="py-3">
                          <FloatSelect list={POS_LIST} isError={false} label="POS ID" value={pos_id} name="pos_id" isRequired={true} onInputChange={(e) => setPOS(e.target.value)} />
                          {InvalidPOSError && <div className="text-danger fw-bold error_text">! {t("send_link_POS_error_message")}</div>}
                        </div>
                      </div>
                       <div className="d-flex justify-content-lg-end justify-content-center py-3">
                        <button className="btn btn-danger px-lg-5" type="submit">{t("next")}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
      </div>
     </>
	)
}

export default AdminSetup;