import {
  GET_ADMIN_START,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_FAILURE,
  SAVE_LOGGEDIN_ADMIN,
  GET_LOGGEDIN_ADMIN,
  REMOVE_LOGGEDIN_ADMIN,
  baseURL
} from './Constants';

export const getEmployeeByPhone = phone => async (dispatch) => {
  dispatch({
    type: GET_ADMIN_START,
    error: null
  });

  let obj = {};
  obj.emp_phone_no = phone;
  
  const URL=`${baseURL}/getEmployeeByPhoneNumber`;
  //const URL=`${baseURL}${GET_EMPLOYEE_URL}?phone_number=${phone}`;

  return await fetch(URL, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify(obj), // body data type must match "Content-Type" header
  })
  .then(res => res.json())
  .then((res) => {
    dispatch({
      type: GET_ADMIN_SUCCESS,
      payload: res.data[0],
      error: null
    });
  })
  .catch((error) => {
    console.log('error getEmployeeByPhone', error);
    dispatch({
      type: GET_ADMIN_FAILURE,
      error: error,
    });
  });
}

export const saveAdmin = (user) => (dispatch) => {
  dispatch({
    type: SAVE_LOGGEDIN_ADMIN,
    payload: user,
    error: null
  });
};

export const getAdmin = (user) => (dispatch) => {
  dispatch({
    type: GET_LOGGEDIN_ADMIN,
    payload: '',
    error: null
  });
};

export const removeAdmin = () => (dispatch) => {
  dispatch({
    type: REMOVE_LOGGEDIN_ADMIN,
    payload: {},
    error: null
  });
};

