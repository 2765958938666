import bg_1 from '../assets/bg_1.png';

const Logo = (props) => {
	const {label = "Feedback"} = props;
	return (
		<div className="logo_container">
            <img src={bg_1} className="bg_image" />
            {/*<p className="logo libreBodoni">{label}</p>*/}
        </div>
	)
}

export default Logo;