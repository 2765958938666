import { useTranslation } from 'react-i18next';
import './card.css';

const CheckBox = (props) => {
	const {isChecked = false, name = "", index = 0, itemIndex = 0, onInputChange = (e) => {}} = props;
    const {img = "", title = "", icon = "Star_jalsa", value = ""} = props.item;
    const {t} = useTranslation();
	
	const onHandleChange = (e) => {
		onInputChange(e);
	}

	return (
		<>
			<input checked={isChecked} value={value} id={`${name}_${index}_${itemIndex}`} name={name} className="radio" type="checkbox" className="a11y" onChange={e => onHandleChange(e)} />
	        <label for={`${name}_${index}_${itemIndex}`} className="checkbox">
	            <div className="text-center" >
	            	<img src={img} style={{width: '100%', height: '60px'}} className="" />
	            	{isChecked && <i class="fa-regular fa-circle-check px-2 checkbox_tick_icon"></i>}
	            </div>
	        </label>
        </>
	)
}

export default CheckBox;