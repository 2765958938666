import React, {useState, useEffect} from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import Customer_Experience_card from "../assets/Customer_Experience_card.png";

export default function SendLinkDialog(props) {
  const {isOpen, children, onHandleCloseDialog, customer_phone="", pos="", invoice = ""} = props;
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  const {t} = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onHandleCloseDialog(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);


  return (
    <React.Fragment>
      <Dialog 
        onClose={handleClose} 
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-label=""
        >
        <div className="px-4 py-2 ">
          <div className="d-flex justify-content-center">
            <img src={Customer_Experience_card} className="thank_you_image" />
          </div>

          <div className="d-flex flex-column justify-content-center text-center dialog">
            <div className="Roboto font24">{t("a")} <a target="_blank" href={`https://prod-pcccrm.web.app/customer-login?phone=${customer_phone}&pos=${pos}&invoice=${invoice}`}>Link</a> has been submitted to your mobile number. Please provide your feedback.</div>
          </div>

          <div className="my-3 m-auto d-flex justify-content-center">
            <button className="btn btn-danger" onClick={handleClose}>{t("close")}</button>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
