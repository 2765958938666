import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import './slideRating.css';

class Emoji extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let button;
    if (this.props.slide == 1) {
      button = <picture>
        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f620/512.webp" type="image/webp" />
        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f620/512.gif" alt="😠" width="48" height="48" />
      </picture>
    } else if (this.props.slide == 2) {
      button = <picture>
        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f61f/512.webp" type="image/webp" />
        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f61f/512.gif" alt="😟" width="48" height="48" />
      </picture>
    } else if (this.props.slide == 3) {
      button = <picture>
        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f610/512.webp" type="image/webp" />
        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f610/512.gif" alt="😐" width="48" height="48" />
      </picture>
    } else if (this.props.slide == 4) {
      button = <picture>
        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f642/512.webp" type="image/webp" />
        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f642/512.gif" alt="🙂" width="48" height="48" />
      </picture>
    } else if (this.props.slide == 5) {
      button = <picture>
        <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f60d/512.webp" type="image/webp" />
        <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f60d/512.gif" alt="😍" width="48" height="48" />
      </picture>
    }
    
    return(
      <div className="smiley">
        {button}
      </div>
    )
  }
}

const SliderRating = (props) => {
  const {t} = useTranslation();
  const {icon = "", indexVal = "01", label = "", value = "3", name="", onInputChange} = props;
  const [sliderValue, setSliderValue] = useState("3");
  const [displayText, setDisplayText] = useState("So So!");
  
  const onSliderChange = (e) => {
    setSliderValue(e.target.value);
    onInputChange(e);
  }

  const getDisplayValue = () => {
    var fieldValue = "";
    switch(sliderValue) {
      case "1":
        fieldValue = "poor";
        break;
      case "2":
        fieldValue = "dissatisfied";
        break;
      case "3":
        fieldValue = "average";
        break;
      case "4":
        fieldValue = "good";
        break;
      case "5":
        fieldValue = "excellent";
        break;
      deafult:
        break;
    }
    setDisplayText(fieldValue);
  }

  useEffect(() => {
    if(value !== "")
    setSliderValue(value);
  }, [value]);

  useEffect(() => {
    getDisplayValue();
  }, [sliderValue]);
  
  return(
    <div className="pb-2">
      <div className="d-flex justify-content-between align-items-center py-1">
        <div className="d-flex align-items-center">
          <img src={icon} className="text-danger" />
          <div className="mx-2 font22 Roboto">
            {indexVal}
            <div className="fw-bold">
              {label}
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <Emoji slide={sliderValue} />
          <div className="display_text OpenSans font18 ms-2">{t(displayText)}</div>
        </div>

      </div>
      <div className="py-2">
          <input name={name} type="range" min="1" max="5" value={sliderValue} onChange={onSliderChange} />
      </div>
    </div>
  )
}

export default SliderRating;

