import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import CustomizedSnackbars from '../components/CustomizedSnackbars';
import RadioView from '../components/RadioView';

import Service1 from './Services/Service1';
import Service2 from './Services/Service2';
import FooterControls from '../components/FooterControls';
import {ServiceData} from '../assets/Data.js';
import {containerVariants} from '../utils/utils';
import FloatTextField from '../components/FloatTextField';

const Services = (props) => {
  const {t} = useTranslation();
  const { formData={}, setFormData , onPageIndex} = props;
  const [isFormValid, setFormValid] = useState(true);
  const [isError, setError] = useState(false);
  const [serviceIndex, setServiceIndex] = useState(1);

  const handleChange = (e) => {
    setFormData({...formData, feedback_details: {...formData.feedback_details, [e.target.name] : e.target.value}});
  }

  const onHandleAlert = () => {
    setError(false);
  }

  const validate = (e) => {
    let isFormValid = true;

    if(serviceIndex === 1) {
      if(f_salesstaffservice === "") {
        isFormValid = false;
      }
      if(f_billingprocedureservice === "") {
        isFormValid = false;
      }

      if(f_goldexchangeservice === "") {
        isFormValid = false;
      }
    }
  
    setFormValid(isFormValid);
    return isFormValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validate();
    onPageIndex(5);
  }

  const handleBack = () => {
    onPageIndex(3);
  }

  useEffect(() => {
    if (!isFormValid) {
      setFormValid(true);
      setError(true);
    }
  }, [isFormValid]);

 useEffect(() => {
    const headerEle = document.querySelector("header");
    if (headerEle) {
      headerEle.scrollIntoView({top: 0,behavior: "smooth"});
    }
  }, []);

  const {
    f_salesstaffservice = "",
    f_billingprocedureservice = "",
    f_goldexchangeservice = "",
    servicecomments = "",
  } = formData.feedback_details;

  return (
    <div>
    <form className="px-lg-3" onSubmit={(e) => handleSubmit(e)} noValidate>
      <h1 className="heading py-3">{t(ServiceData.title)}</h1>
      <div>

        {serviceIndex === 1 && <Service1 formData={formData} setFormData={setFormData} />}
        {serviceIndex === 2 && <Service2 formData={formData} setFormData={setFormData} />}
      </div>
      <FooterControls onBackBtnClick={handleBack} />
    </form>
    {isError && <CustomizedSnackbars isShow={isError} text={t("Choose_one")} showAlert={(isHide) => onHandleAlert(isHide)} />}
    </div>
  );
}

export default Services;