import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import CustomizedSnackbars from '../../components/CustomizedSnackbars';
import Loading from '../../components/Loading';
import MobileHeader from '../../components/MobileHeader';
import Header from '../../components/Header';
import UserProfile from '../../components/UserProfile';

import FloatTextField from '../../components/FloatTextField';
import SendLinkDialog from '../../components/SendLinkDialog';
import {initialfeedbackFormData, initialUserFormData, isPhoneNumberValid} from '../../utils/utils';
import {addFeedback, saveFeedbackData} from '../../Actions/feedback.actions';
import {saveCustomerData} from '../../Actions/customer.actions';
import Logo from '../../components/Logo';

const SendLink = () => {
  const {t} = useTranslation();
  const titleVal = "Send Link - pcccrm";
	const hrefVal = window.location.href;
	const navigate = useNavigate();
  const dispatch = useDispatch();

  const InitialErrors = {
    InvalidNameError: false,
    InvalidPhoneError : false,
    InvalidVoucherError : false,
    InvalidInvoiceNumberError : false,
    InvalidSalesNameError : false
  }

  const [feedbackData, setFeedbackData] = useState(initialfeedbackFormData);
  const [customerData, setCustomerData] = useState(initialUserFormData);
	const [errors, setErrors] = useState(InitialErrors);
  const [isFormValid, setFormValid] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  
  const addFeedbackAction = addFeedback;
  const saveCustomerDataAction = saveCustomerData;
  const saveFeedbackDataAction = saveFeedbackData;

  const store_legal_Entity_id = localStorage.getItem("Device_Legal_Entity");
  const store_pos_id  = localStorage.getItem("Device_POS_ID");

	const handleSubmit = (e) => {
		e.preventDefault();
   
    const isFormValid = validate(false);
    if(isFormValid) {
      customerData.name = customer_name;
      customerData.phone_no = customer_phone;
      customerData.pos_id = store_pos_id;
      customerData.legal_entity_id = store_legal_Entity_id;

      feedbackData.feedback_details.isFeedbackFromPOS = "true";
      localStorage.setItem("isFeedbackFromPOS", "true");
      dispatch(saveCustomerDataAction(customerData));
      dispatch(saveFeedbackDataAction(feedbackData));
      navigate("/feedback");
    }
	}

  const sendLinkBtnClick = () => {
    const isFormValid = validate(true);

    if(isFormValid) {
      customerData.phone_no = customer_phone;
      customerData.name = customer_name;
      customerData.pos_id = store_pos_id;
      customerData.legal_entity_id = store_legal_Entity_id;
      dispatch(saveCustomerDataAction(customerData));
      postFeedback();
    } else {
      setOpen(false);
    }
  }

  const validate = (isSendLinkActivate = false) => {
    let isFormValid = true;
    let isInvalidCustomerNameError = false;
    let isInvalidPhoneError = false;
    let isInvalidVoucherError = false;
    let isInvalidInvoiceNumberError = false;
    let isInvalidSalesNameError = false;

    if(customer_name === "") {
      isInvalidCustomerNameError = true;
      isFormValid = false;
    }

    if(customer_phone === "" || !isPhoneNumberValid(customer_phone)) {
      isInvalidPhoneError = true;
      isFormValid = false;
    }

    if(salespersonname === "") {
      isInvalidSalesNameError = true;
      isFormValid = false;
    }

    if(isSendLinkActivate) {
      /*
      if(voucher_code === "") {
        isInvalidVoucherError = true;
        isFormValid = false;
      }
      */
      if(invoiceno === "") {
        isInvalidInvoiceNumberError = true;
        isFormValid = false;
      }
    }

    setErrors({...errors,
      InvalidPhoneError : isInvalidPhoneError,
      InvalidSalesNameError : isInvalidSalesNameError,
      InvalidVoucherError: isInvalidVoucherError,
      InvalidInvoiceNumberError : isInvalidInvoiceNumberError,
      InvalidNameError : isInvalidCustomerNameError
    });

    setFormValid(isFormValid);
    return isFormValid;
  }

  const postFeedback = async () =>  {
    const URL = "https://api.smmc.dev:8908/smmc-pcchandra/addfeedback";
    setLoading(true);
    const d = new Date();
    feedbackData.datetimestamp = d.getTime();   

    feedbackData.legal_entity_id = store_legal_Entity_id; 

    await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(feedbackData) // body data type must match "Content-Type" header
    })
    .then(res => res.json())
    .then((res) => {
      setError(false);
      setLoading(false);
      setOpen(true);
      SendLinkMessage();
    })
    .catch((error) => {
      setLoading(false);
      console.log('error', error);
      setError(true);
      setOpen(false);
    });
  }

  const SendLinkMessage = async () =>  {
    
    const URL = "https://api.smmc.dev:8908/smmc-pcchandra/sendFeedbackSms";
    const obj = {};

    obj.sms_pos_id = store_pos_id || "";
    obj.mobiles =  `91${customer_phone}`;
    obj.customer_name = customer_name || "Guest";
    obj.feedback_url = `https://prod-pcccrm.web.app/customer-login?phone=${customer_phone}&pos=${pos_id}&invoice=${invoiceno}`

    await fetch(URL, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(obj) // body data type must match "Content-Type" header
    })
    .then(res => res.json())
    .then((res) => {
     
    })
    .catch((error) => {
      console.log('error', error);
      
    });
  }

  const handleChange = (e) => {
    setFeedbackData({...feedbackData, [e.target.name] : e.target.value});
  }

  const handleDetailsChange = (e) => {
    setFeedbackData({...feedbackData, feedback_details: {...feedbackData.feedback_details, [e.target.name] : e.target.value}});
  }

  const handleAdminChange = (e) => {
    setFeedbackData({...feedbackData, admin_details: {...feedbackData.admin_details, [e.target.name] : e.target.value}});
  }

  const onHandleAlert = () => {
   setError(false);
  }

  const onDialogClose = () => {
    setOpen(false);
    setFeedbackData({...feedbackData, datetimestamp: 0, ["customer_name"]  : "", ["customer_phone"]  : "", admin_details : {...feedbackData.admin_details, "voucher_code" : "", "invoiceno" : ""} });
  }

 
  const {
    datetimestamp = 0,
    customer_phone = "",
    customer_name = "",
    legal_entity_id = "",
    pos_id = ""
  } = feedbackData;

  const {
    salespersonname = ""
  } = feedbackData.feedback_details;

  const {
    voucher_code = "",
    invoiceno = ""
  } = feedbackData.admin_details;
	
  const {
    details,
    loading,
    error
  } = useSelector(({ feedback: { details, loading, error } }) => ({ details, loading, error }));

  const {
    details : loggedAdminDetails
  } = useSelector(({ loggedAdmin: { details, loading, error } }) => ({ details, loading, error }));

  useEffect(() => {
    if(loggedAdminDetails) {
      addAdminDetailsToFeedback();
    }
  }, []);

  useEffect(() => {
    if (!isFormValid) {
      const firstInvalidEle = document.querySelector('[aria-invalid="true"]');
      if (firstInvalidEle) firstInvalidEle.focus();
      setFormValid(true);
    }
  }, [isFormValid]);

  const addAdminDetailsToFeedback = () => {
    if(loggedAdminDetails && loggedAdminDetails.emp_name) {
      
      // legal_entity_id, pos_id
      // admin_details - pos
      // feedback_details - salespersonname

      setFeedbackData(
        {...feedbackData, 
        "legal_entity_id" : store_legal_Entity_id,
        "pos_id" : store_pos_id,
        feedback_details: {...feedbackData.feedback_details, "salespersonname" : loggedAdminDetails.emp_name}
      });
    }
  }

  const {
    InvalidNameError,
    InvalidPhoneError,
    InvalidVoucherError,
    InvalidInvoiceNumberError,
    InvalidSalesNameError
  } = errors;

  //console.log(feedbackData)

	return (
	  <>
    	<Helmet>
      	<title>{titleVal}</title>
      	<meta name="description" content="Login meta"></meta>
      	<link rel="canonical" href={hrefVal} />
    	</Helmet>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-0">
            <Logo />
          </div>

          <div className="align-items-center col-lg-8 col-md-12">
            <div className="mobile_header">
              <MobileHeader label = "Feedback" />
            </div>
              <div className="">
              <Header isShow={true} />
              </div>
              <div className="send_link_container">
                <form className="px-lg-3 px-1 pt-2" onSubmit={e=>handleSubmit(e)} autoComplete="off" data-group-name="digits" data-autosubmit="false" noValidate>
                  <div className="row">
                    <div className="col-lg-12 w-100 mt-3">
                      <div>
                        <h1 className="heading fw-bold py-3">{t("send_link_heading")}</h1>
                      </div>

                      <div className="pb-2">
                        <FloatTextField name="customer_name" isError={InvalidNameError} label={t("customer_name")} value={customer_name} isRequired={true} onInputChange={(e) => handleChange(e)} />
                        {InvalidNameError && <div className="text-danger fw-bold error_text">! {t("customer_name_error")}</div>}
                      </div>

                      <div className="py-2">
                        <FloatTextField name="customer_phone" isError={InvalidPhoneError} label={t("send_link_phone_number_placeholder")} value={customer_phone} isRequired={true} onInputChange={(e) => handleChange(e)} />
                        {InvalidPhoneError && <div className="text-danger fw-bold error_text">! {t("login_invalid_mobile_number")}</div>}
                      </div>

                      <div className="py-2">
                    	  <FloatTextField isError={InvalidVoucherError} label={t("voucher_code")} value={voucher_code} name="voucher_code" isRequired={false} onInputChange={(e) => handleAdminChange(e)} />
                  	    {InvalidVoucherError && <div className="text-danger fw-bold error_text">! {t("send_link_voucher_code_error_message")}</div>}
                      </div>

                  	  <div className="py-2">
                    	  <FloatTextField isError={InvalidInvoiceNumberError} label={t("invoice_no")} value={invoiceno} name="invoiceno" isRequired={false} onInputChange={(e) => handleAdminChange(e)} />
                  	    {InvalidInvoiceNumberError && <div className="text-danger fw-bold error_text">! {t("send_link_invoice_number_error_message")}</div>}
                      </div>

                  	  <div className="py-2">
                  		  <FloatTextField isError={InvalidSalesNameError} label={t("customer_executive_name")} value={salespersonname} name="salespersonname" isRequired={true} onInputChange={(e) => handleDetailsChange(e)} />
                  	    {InvalidSalesNameError && <div className="text-danger fw-bold error_text">! {t("send_link_sales_name_error_message")}</div>}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-lg-end justify-content-center px-3 py-3">
                  	<button className="btn btn-outline-danger mx-lg-5 mx-2 px-lg-5 px-4" type="button" onClick={() => sendLinkBtnClick()}>{t("send_link")}</button>
                  	<button className="btn btn-danger px-5" type="submit">{t("feedback")}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
      {(loading || isLoading) && <Loading />}
      {isOpen && <SendLinkDialog isOpen={isOpen} onHandleCloseDialog={onDialogClose} customer_phone={customer_phone} pos={pos_id} invoice={invoiceno} />}
      {(error || isError) && <CustomizedSnackbars isShow = {error || isError} text={t('server_error')} showAlert={(isHide) => onHandleAlert(isHide)} />}
    </>
	)
}

export default SendLink;