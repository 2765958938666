import pc_logo from '../assets/pc_logo.png';
import logo_2 from '../assets/Feedback_m.png';

const MobileHeader = (props) => {
	const {label = ""} = props;

	return (
		<div className="d-flex justify-content-between align-items-center header_container">
			<div className="d-flex align-items-center">
				<img src={logo_2} style={{width: "60%"}} />
			</div>
			<div>
				<img src={pc_logo}  style={{width: "100%"}} />
			</div>	
		</div>
	)
}

export default MobileHeader;