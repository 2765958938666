import { useTranslation } from 'react-i18next';

const FooterControls = (props) => {
	const { onBackBtnClick } = props;
  const {t} = useTranslation();

	const handleBackBtnClick = () => {
		onBackBtnClick();
	}

	return (
    <div className="d-flex justify-content-end my-4">
      <button type="button" className="btn btn-outline-danger px-5" onClick={handleBackBtnClick}>
        <span>{t("back")}</span>
      </button>

      <button type="submit" className="btn btn-danger px-5 mx-2">
        <span className="">{t("next")}</span>
      </button>
    </div>    
	)
}

export default FooterControls;