import {
  ADD_FEEDBACK_START,
  ADD_FEEDBACK_SUCCESS,
  ADD_FEEDBACK_FAILURE,
  GET_FEEDBACK_START,
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_FAILURE,
  SET_FEEDBACK_DATA,
  REMOVE_FEEDBACK_DATA,
  baseURL
} from './Constants';
import {formatDate} from '../utils/utils';

export const addFeedback = feedbackData => async (dispatch) => {
  dispatch({
    type: ADD_FEEDBACK_START,
    error: null
  });
  
  const URL=`${baseURL}/addfeedback`;
  const d = new Date();
  
  feedbackData.datetimestamp = d.getTime();
  /*feedbackData.datetimestamp = formatDate(new Date());*/

  return await fetch(URL, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify(feedbackData), // body data type must match "Content-Type" header
  })
  .then(res => res.json())
  .then((res) => {
    dispatch({
      type: ADD_FEEDBACK_SUCCESS,
      payload: feedbackData,
      error: null
    });
  })
  .catch((error) => {
    console.log('error addFeedback', error);
    dispatch({
      type: ADD_FEEDBACK_FAILURE,
      error: error,
    });
  });
}

export const saveFeedbackData = (feedbackData) => (dispatch) => {
  dispatch({
    type: SET_FEEDBACK_DATA,
    payload: feedbackData,
    error: null
  });
};

export const getfeedbackByCustomerPhoneNoAndPOS = (phone, pos) => async (dispatch) => {
  dispatch({
    type: GET_FEEDBACK_START,
    error: null
  });
  
  const URL=`${baseURL}/getfeedbackByCustomerPhoneNoAndPOS`;

  let obj = {};
  obj.customer_phone = phone;
  obj.pos_id = pos;
 
  return await fetch(URL, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    body: JSON.stringify(obj), // body data type must match "Content-Type" header
  })
  .then(res => res.json())
  .then((res) => {
    dispatch({
      type: GET_FEEDBACK_SUCCESS,
      payload: res.data[0],
      error: null
    });
  })
  .catch((error) => {
    console.log('error addFeedback', error);
    dispatch({
      type: GET_FEEDBACK_FAILURE,
      error: error,
    });
  });
}

export const removeFeedbackData = () => (dispatch) => {
  dispatch({
    type: REMOVE_FEEDBACK_DATA,
    payload: {},
    error: null
  });
};






