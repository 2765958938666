import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import FloatSelect from '../../components/FloatSelect';
import FloatTextField from '../../components/FloatTextField';
import DatePickerField from '../../components/DatePickerView';
import Header from '../../components/Header';
import Switch from '@mui/material/Switch';
import {Countries, States} from '../../utils/utils';

import {isEmailValid, Salutation, Days, Months, generateYears, getDate, getMonth, getYear, initialUserFormErrorData, iSDateValid} from '../../utils/utils';


const UserContact = (props) => {
	const {t} = useTranslation();
	const {userData={}, onUserInfoViewChange, handleChange, handleAddressChange, handleBirthDayUpdate, handleConsentChange} = props;
	const [isFormValid, setFormValid] = useState(true);
	const [errors, setErrors] = useState(initialUserFormErrorData);

	const [date, setDate] = useState(getDate(userData.birthday));
	const [month, setMonth] = useState(getMonth(userData.birthday));
	const [year, setYear] = useState(getYear(userData.birthday));
	
	const {
		gender = "",
		name = "",
	    phone_no = "",
	    email = "",
	    birthday = "",
	    onboarding_date = 0,
	    address = {
	      city : "",
	      state : "",
	      country : "",
	      pincode: "",
	      details: ""
	    },
	    other_details = {
      		consent: "true"
    	},
	    legal_entity_id = ""
  	} = userData;

	const handleUserContactSubmit = (e) => {
		e.preventDefault();
    	const isFormValid = validate();
    	if(isFormValid) {
      		onUserInfoViewChange(2);
    	}
	}

	const validate = (e) => {
    	let isFormValid = true;
    	let isInvalidGenderError = false;
	    let isInvalidNameError = false;
	    let isInvalidPhoneError = false;
	    let isInvalidEmailError = false;
	    let isInvalidBirthdayError = false;

	    if(gender === "") {
	      isInvalidGenderError = true;
	      isFormValid = false;
	    }

	    if(name === "") {
	      isInvalidNameError = true;
	      isFormValid = false;
	    }

	    if(phone_no === "") {
	      isInvalidPhoneError = true;
	      isFormValid = false;
	    }

	    if(birthday === "" || !iSDateValid(birthday)) {
	    	isInvalidBirthdayError = true;
	        isFormValid = false;
	    }

	    setErrors({...errors,
	    	genderError: isInvalidGenderError,
      		nameError : isInvalidNameError,
      		phoneError : isInvalidPhoneError,
      		emailError: isInvalidEmailError,
      		birthdayError : isInvalidBirthdayError
    	});

    	setFormValid(isFormValid);
    	return isFormValid;
	}

	useEffect(() => {
		onUserInfoViewChange(1);
	}, []);

	useEffect(() => {
		const newBirthDay = date + "-" + month + "-" + year;
		handleBirthDayUpdate(newBirthDay);
	}, [date, month, year]);

  	useEffect(() => {
   		setDate(getDate(birthday));
		setMonth(getMonth(birthday));
		setYear(getYear(birthday));
    }, [birthday]);

  	const {
  		genderError = false,
    	phoneError = false,
    	nameError = false,
    	emailError = false,
    	birthdayError = false
    } = errors;

    const isNewUser = (onboarding_date === 0) ? true : false;

   const title_text = isNewUser ? `${t('welcome')} P.C Chandra Jewellers` : `Hello ${gender} ${name} ${t("welcome_back")}!`;
   const sub_title_text = isNewUser ? t("spam_you_heading") : t("following_details_heading");

	return (
		<div className="container-fluid">
			<div className="px-lg-3 px-1">
				<h1 className="heading">{title_text}</h1>
				<h2 className="heading pt-2">{sub_title_text}</h2>
			</div>
			<form onSubmit={(e) => handleUserContactSubmit(e)} className="px-lg-3 pt-4" noValidate>
				<div className="row">
					<div className="col-4">
	                    <FloatSelect name="gender" label={t("salutation")} list={Salutation} value={gender} onInputChange = {(e) => handleChange(e)}  isRequired = {true} isError = {genderError} />
	                	{genderError && <p className="text-danger fw-bold">! {t("choose_title_error")}</p>}
	                </div>
	                <div className="col-8">
	                    <FloatTextField name="name" label={t("your_name")} value={name} onInputChange = {(e) => handleChange(e)}  isRequired = {true} isError = {nameError} />
	                	{nameError && <p className="text-danger fw-bold">! {t("customer_name_error")}</p>}
	                </div>
				</div>

				<div className="row">
	                <div className="col-lg-4 col-md-4 my-3">
	                    <FloatTextField name="phone_no" label={t("phone_number")} isDisabled={true} value={phone_no} onInputChange = {(e) => handleChange(e)} isRequired = {true} isError = {phoneError} />
	                	{phoneError && <p className="text-danger fw-bold">! {t("phone_number_error")}</p>}
	                </div>

	                <div className="col-lg-8 col-md-8 my-3">
	                    <FloatTextField name="email" label={t("email_id")} value={email} onInputChange = {(e) => handleChange(e)} isRequired = {false} isError = {emailError} />
	                	{emailError && <p className="text-danger fw-bold">! {t("email_id_error")}</p>}
	                </div>    
				</div>
				
				<div className="row">
					<div className="col-lg-4 col-md-4 my-3">
	                    <FloatSelect name="birthday" label={t("birth_day")} isRequired = {true} list={Days} value={date} onInputChange = {(e) => setDate(e.target.value)} isError = {birthdayError}  />
	                </div>
	                <div className="col-lg-4 col-md-4 my-3">
	                    <FloatSelect name={birthday} label={t("month")} isRequired = {true} list={Months} value={month} list={Months} onInputChange = {(e) => setMonth(e.target.value)} isError = {birthdayError}  />
	                </div>
	                <div className="col-lg-4 col-md-4 my-3">
	                    <FloatSelect name={birthday} label={t("year")} isRequired = {true} list={generateYears()} value={year} onInputChange = {(e) => setYear(e.target.value)} isError = {birthdayError}  />
	                </div>
				</div>
				
				{birthdayError && <p className="text-danger fw-bold">! {t("date_error")}</p>}
				
				<div className="row">
					<div className="col-lg-4 col-md-4 my-3">
	                    <FloatTextField name="pincode" label={t("pincode")} value={address.pincode} onInputChange = {(e) => handleAddressChange(e)} />
	                </div>
	                <div className="col-lg-8 col-md-8 my-3">
	                    <FloatSelect name="country" label={t("country")} value={address.country} list={Countries} onInputChange = {(e) => handleAddressChange(e)} />
	                </div>
				</div>

				<div className="row">
					<div className="col-lg-4 col-md-4 my-3">
	                    <FloatSelect name="state" label={t("state")} value={address.state}  list={States} onInputChange = {(e) => handleAddressChange(e)} />
	                </div>
	                <div className="col-lg-8 col-md-4 my-3">
	                    <FloatTextField name="city" label={t("city")} value={address.city} onInputChange = {(e) => handleAddressChange(e)} />
	                </div>
				</div>

				<div className="d-flex justify-content-between align-items-center pb-3">
                    <div className="terms_text OpenSans">
                      {t("login_agree_terms_condition")}
                    </div>
                    <div>
                      <Switch defaultChecked name="consent" value={other_details.consent} onChange = {(e) => handleConsentChange(e)} />
                    </div>
                </div>
				<div className="d-flex justify-content-end">
                    <button className="nextBtn px-lg-3">{t("next")}</button>
                </div>
			</form>
		</div>
	)
}

export default UserContact;